a,
a:visited {
    text-decoration: none;
    text-align: center;
}

html {
    background-color: transparent;
}

body {
    --rosy-pink: #f26167;
    --dark-blue-grey: #211c51;

    background-color: #f7f7f7;
    padding-block: 0;
    #root > div {
        overflow-y: scroll !important;
    }
}

.welcome-page {
    display: flex;
    gap: 80px;
    max-width: 1062px;
    min-width: 1062px;
    margin: auto;
    .content {
        display: flex;
        flex-direction: column;
        width: 500px;
        .mysizeid-logo {
            width: 181px;
            height: 36px;
            margin-block-start: 30px;
            margin-block-end: 50px;
        }
        .welcome-title {
            color: var(--dark-blue-grey);
            font-size: 95px;
            font-weight: 700;
            line-height: 88px;
        }
        .subtitle {
            color: var(--dark-blue-grey);
            font-size: 29px;
            margin-block-end: 0;
        }
        .buttons-container {
            margin-block-start: 50px;
            margin-block-end: 80px;
            display: flex;
            justify-content: space-between;
            .button {
                display: grid;
                place-content: center;
                text-transform: uppercase;
                width: 232px;
                height: 60px;
                font-size: 19px;
                font-weight: bold;
                border-radius: 3px;
                padding: 0;
            }
            .book-meeting-button {
                color: var(--rosy-pink);
                background-color: white;
                border: 1px solid var(--rosy-pink);
            }
            .signin-button {
                color: white;
                background-color: var(--rosy-pink);
                margin-right: 25px;
            }
        }
        .details {
            display: grid;
            grid-template-columns: 30% 1px 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 50px 40px;
            width: 500px;
            .spacer {
                grid-column: 2;
                grid-row: 1 / 3;
                background-color: #b2b2b2;
            }
            > div {
                display: flex;
                gap: 19px;
                align-items: center;
                img {
                    width: 29px;
                    height: 33px;
                }
                label {
                    color: var(--dark-blue-grey);
                    font-size: 19px;
                }
            }
        }
        .more {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            color: var(--dark-blue-grey);
            font-size: 19px;
            margin-block: 35px;
            background-color: transparent;
            border: none;
            img {
                width: 9px;
                height: 5px;
                transition: transform 500ms;
            }
            &.up {
                img {
                }
            }
            &.down {
                img {
                    transform: rotate(180deg);
                }
            }
        }
        .list-container {
            margin-block-end: 200px;
            .solution-steps {
                width: 80vw;
                max-width: 1022px;
                padding-inline: 20px;
                li {
                    font-size: 17px;
                    color: var(--dark-blue-grey);
                    margin-block-end: 25px;
                }
            }
        }
    }
    .image {
        img {
            width: 457px;
            height: 630px;
            object-fit: contain;
        }
    }
}

@media only screen and (max-width: 1085px) {
    .welcome-page {
        justify-content: center;
        min-width: unset;
        .content {
            .more {
                justify-content: center;
            }
            .list-container {
                .solution-steps {
                    max-width: 500px;
                }
            }
        }
        .image {
            display: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .welcome-page {
        padding: 0 5%;
        min-width: 270px;
        .content {
            width: 100%;
            .mysizeid-logo {
                margin-block-end: 0;
            }
            .welcome-title {
                font-size: 60px;
                line-height: 0.95;
                font-weight: 800;
                letter-spacing: 1.5px;
                margin-block: 3rem;
            }
            .subtitle {
                font-size: 16px;
                margin-block-start: 0;
            }
            .buttons-container {
                .button {
                    font-size: 14px;
                    max-width: 45%;
                    padding-inline: 5%;
                }
            }
            .details {
                width: 100%;
                grid-template-columns: max-content 1px 1fr;
                gap: 40px 5vw;
                div {
                    min-width: max-content;
                }
                label {
                    font-size: 3.5vw !important;
                }
                img {
                    width: 5vw !important;
                }
            }
        }
    }
}

// @media only screen and (max-width: 399px) {
//     .middle-page {
//         padding: 0px 7% 0 5%;

//         &-upper-left {
//             width: 300px;
//         }
//     }
// }
