.name {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  h1 {
    width: 219px;
    height: 40px;

    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
    margin-bottom: 30px;
  }
  input {
    width: 392.1px;
    height: 51px;

    border: solid 1px #afafaf;
    background-color: #ffffff;
    color: #211c51;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;

    font-style: normal;
    line-height: normal;
    padding-left: 20px;
    box-sizing: border-box;
    outline: none;
  }

  div {
    width: 232px;
    height: 60px;
    margin: 25px 80.1px 0 80px;
    background-color: #f26167;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
  }

}

