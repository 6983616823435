.circular-progress{
    .progress-precent{
        position: absolute;
        display: flex!important;
        margin: 0;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
        transform: translateY(-1px);
    }
}