.uninstall-thank-you {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  &__logo {
    height: 70px;
  }

  &__feedback {
    font-weight: bold;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
 .uninstall-thank-you{
  padding: 10px;
  &__logo {
    height: unset;
    width: 60%;
    min-width: 200px;
  }
  &__feedback {
    white-space: break-spaces;
    font-size: clamp(12px, 3.5vw, 16px);
  }
 }
}
