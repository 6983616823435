.advanced-widget-settings {
    background: white;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    font-family: "Roboto";

    &-preferences {
        width: 250px;
        margin-left: 70px;
    }

    &__preview {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 100px;
    }

    &__title {
        font-size: 15px;
        line-height: 1.87;
        color: #000;
        text-align: center;
        margin-bottom: 15px;
    }
}

.advanced-widget-settings-save-button {
    width: 100%;
    margin-top: 20px;
    color: white;
    background: #f8686e;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    height: 18px;
    max-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--disabled {
        background-color: #b7b7b7;
        pointer-events: none;
        cursor: not-allowed;
    }
}
