.size-chart-unit {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    width: 242px;
    height: 40px;
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
  }
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    width: 281px;
    justify-content: space-between;
  }

  .button {
    width: 134px;
    height: 51px;
    border: solid 1px #afafaf;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
    box-sizing: border-box;
    cursor: pointer;
    &:hover
    {
      border: 2px solid #afafaf;
    }
  }

  .active {
    border: solid 2px #f26167;
    color: #f26167;
    &:hover
    {
      border: solid 2px #f26167;
    }
  }

  .next-button {
    width: 232px;
    height: 60px;
    margin: 25px 79px 0 78px;
    background-color: #f26167;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    color: #ffffff;
  }
}
