
.MuiButtonBase-root{
    background-color: white !important;
    min-width: 98px !important;
    height: 50px;
}

.MuiList-root{
    max-height: 250px;
}

.MuiButton-root{
    text-transform: unset !important;
    height: 36px;
    padding: 0 !important;
    padding-right: 15px !important;
    padding-left: 4px !important;
}

.MuiListItem-button:hover{
    background-color: #f4f4f4 !important;
}


.MuiPaper-root {
    // margin-top: 50px;
    // top: 73px !important;

}

  
    .input-wrapper{
        width: 305px !important;
        height: 64px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: Roboto;

        input{
            width: 276px;
            height: 37px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #afafaf;
            font-size: 18px;
            font-family: Roboto;
            background-color: #fff;
            padding-left: 10px;
            
    }

    input:focus{
        outline: none;
    }
}




.size-chart-external {
    .MuiButtonBase-root {
        width: 220px !important;
    }

    .MuiPaper-root {
        // margin-top: 150px ;
        // top: 273px ;
    }
}
