.something-went-wrong-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(153, 153, 155, 0.527);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    

  .something-went-wrong {
    height: 200px;
    width: 400px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: red;
    font-family: Roboto;
    border-radius: 6px;

    h1 {
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      padding: 0;
      margin-bottom: 30px;
    }
    .try-again-btn {
      background-color: #f8686e;
      width: 132px;
      height: 50px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      cursor: pointer;
    }
  }
}
