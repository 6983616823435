.standart-size-chart-creator-wrapper {
  width: 1040px;
  // height: 612px;
  padding: 34px 98px;
  box-sizing: border-box;
  position: fixed;
  z-index: 500;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -520px;
  background: #f7f7f7;
  border-radius: 8px;
  // position: relative;
  // padding-bottom: 20px;

  .x-img {
    width: 25px;
    position: absolute;
    right: 98px;
    cursor: pointer;
  }

  .tabs-container {
    margin-top: 20px;
    height: 66px;
    width: 100%;
    display: flex;

    .item {
      width: 282px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: 18px;
      color: #919191;
      cursor: pointer;

      &.active {
        // border-radius: 5px;
        box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.11);
        border: solid 1px #c6c6c6;
        // border-bottom: none;
        background-color: #ffffff;
        font-weight: bold;
        color: #f26167;
        border-bottom: 1px solid #c6c6c6;
      }
    }
  }

  // .chart-btn-section{
  //   display: flex;
  //   align-items: center;

  //   .save-button{
  //     width: 232px;
  //     height: 60px;
  //     background-color: #F7F7F7;
  //     border: none;
  //     font-family: Roboto;
  //     display: flex;
  //     justify-content: center;
  //     border: solid 2px #f8686e;
  //     align-items: center;
  //     font-size: 19px;
  //     font-weight: bold;
  //     color: #ffffff;
  //     cursor: pointer;
  //     color: #f8686e;
  //   }

  //   .submit-button{
  //     width: 232px;
  //     height: 60px;
  //     background-color: #f26167;
  //     border: none;
  //     font-family: Roboto;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     font-size: 19px;
  //     font-weight: bold;
  //     color: #ffffff;
  //     cursor: pointer;
  //   }
  // }

  .basic-size-table-header {
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    //text-align: center;
    color: #211c51;
  }

  .size-chart-creator-table {
    box-sizing: border-box;
    width: 847px;
    height: auto;
    // border: 1px solid #c6c6c6;
    margin: 0 auto;

    .size-chart-creator-table-header {
      width: 847px;
      height: 61px;
      padding: 18px 29px;
      box-sizing: border-box;
      background-color: #211c51;
      color: white;
      align-items: center;
      display: flex;

      .size-chart-name {
        font-weight: bold;
        font-family: Roboto;
        padding-right: 20px;
      }
    }

    .standart-size-chart-creator-container {
      position: relative;

      .add-size-icon {
        position: absolute;
        bottom: -60px;
        left: -6px;
        cursor: pointer;
      }

      .sizes-container {
        display: grid;
        height: 208px;
        overflow: auto;

        .size-header-item {
          height: 60px;
          display: flex;
          display: inline-flex;
          height: 60px;
          box-sizing: border-box;
          border: 1px solid #c6c6c6;
          justify-content: center;
          align-items: center;
          color: #1f1b45;
          font-family: Roboto;
          font-weight: bold;
        }
      }
    }
  }
}
