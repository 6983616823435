.select-plan {
    padding-top: 58px;
    margin: auto;
    width: 600px;
    margin-top: 100px;
    font-family: "Roboto";

    a:-webkit-any-link {
        text-decoration: none;
    }

    &__title {
        font-size: 35px;
        text-align: center;
        font-weight: bold;
    }
    &__description {
        text-align: center;
    }
    &__select-plan {
        text-align: center;
        background-color: #f8686e;
        border-radius: 20px;
        width: max-content;
        padding: 15px 20px;
        margin: auto;
        color: white;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;

    }

}