.main-header {
    font-family: Roboto;
    font-size: 36px;
    text-align: center;
    font-weight: normal;
    color: #211c51;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0;
  
    &.bold {
      font-weight: bold;
    }
  }


  .getting-started-wrapper{
    width: 100%;
    position: absolute;
    margin: auto;
    // padding-top: 10px;
    z-index: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20;
    display: block;
      &.hide{
        display: none;
      }
  }