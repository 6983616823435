.widget-gender-settings-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #f4f4f4 solid;
    border-top: 1px #f4f4f4 solid;
    position: relative;

    .widget-gender-settings-info-bubble {
      width: 320px;
      height: auto;
      background: white;
      padding: 20px;
      color: #f8686e;
      font-size: 15px;
      border-radius: 7px 7px 7px 0px;
      position: absolute;
      bottom: 60%;
      left: 125px;
      box-shadow: 0px 0px 20px #b6b6b6;
      font-size: 15px;
      z-index: 26;
  }

    &-title {
        width: auto;
        display: flex;
        justify-content: center;
        font-size: 15px;
        color: #000;

        &-info-image {
            margin-left: 10px;
        }
    }
    
    .switch {
        position: relative;
        display: inline-block;
        width: 31px;
        height: 19px;
    
        input {
            opacity: 0;
            width: 0;
            height: 0;
      }
    
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
    
      .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #f8686e;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #f8686e;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
    }
}