.faq-wrapper {
  width: 1040px;
  margin: auto;
  margin-top: 10px;
}


.container {
    width: 1040px;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-size: 20px;
    margin: auto;
    
    .wrepper{
      padding: 50px;
      padding-top: 15px;
      overflow-y:scroll;
      height:550px;
    }
    h1{
      text-align: center;
      margin: 0;
    }
    a{
        color: black;
        display: inline-block;
        text-decoration: underline;
        font-weight: bold;
    }
    a:-webkit-any-link {
      text-decoration: underline!important;
  }
    /**
  * ----------------------------------------------
  * Demo styles
  * ----------------------------------------------
  **/
  
    .accordion {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
  
    .accordion__item + .accordion__item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  
    p {
      width: auto;
      height: auto;
      text-align: left;
      margin: 0;
      font-size: 15px;
    //   display: flex;
    }
  
    .accordion__button {
      background-color: #f4f4f4;
      color: #444;
      cursor: pointer;
      text-decoration: none;
      outline: none;
      padding: 15px;
      text-align: left;
      border: none;
      font-family: 'Roboto', sans-serif;
      
  
      &:hover {
        background-color: #ddd;
      }
  
      &:before {
        display: inline-block;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
      }
  
      &[aria-expanded='true']::before, &[aria-selected='true']::before {
        transform: rotate(45deg);
        transition: 0.5s;
      }
    }
  
    .accordion__panel {
      padding: 20px;
      animation: fadein 1s ease-in;
      height: auto;
    }
  
    /* -------------------------------------------------- */
    /* ---------------- Animation part ------------------ */
    /* -------------------------------------------------- */
  
    @keyframes fadein {
      0% {
        opacity: 0;
      }
  
      100% {
        opacity: 1;
      }
    }
   
  }
