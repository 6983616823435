
$mysize-blue:  #211c51;

.getting-started {
    background-color: #f7f7f7;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 90px;

    

    a:-webkit-any-link {
        text-decoration: none;
    }


    .right-side {
        color: $mysize-blue;
        width: 450px;

        &__title {
            font-size: 40px;
            font-weight: bold;
        }
        &__description-1 {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;

        }
        &__description-2 {
            line-height: 1.5;
            font-size: 20px;
        }

        &__lets-talk-btn {
            border-radius: 36px;
            background-color: #f8686e;
            width: 299px;
            height: 62px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.52;
            font-size: 23px;
            cursor: pointer;

        }

        &__set-up-own-btn { 
            line-height: 1.58;
            font-weight: 500;
            font-size: 19px;
            cursor: pointer;
            margin-top: 25px;
            display: flex;
            align-items: center;
            width: 300px;
            text-align: center;
            justify-content: center;

            &:visited, &:hover, &:active, &:link {
                color: inherit;
            }


            img{
                height: 13px;
                margin-left: 5px;
            }

        }
    }

    .left-side {
        width: 492px;
        height: 457px;

    }
}