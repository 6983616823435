
$gray-background: #f7f7f7;


.welcome {
    background-color: white;
    width: 100%;
    height: 100%;
    margin: 0;

  

    a:-webkit-any-link  {
        text-decoration: none;
        // color: inherit;
    }

 
    .header {
        background-color: $gray-background;
        padding: 80px 0;
        &__title {
            font-size: 40px;
            font-weight: bold;
            color: #211c51;
            text-align: center;
            margin-bottom: 35px;
        }
        &__description {
            font-size: 20px;
            line-height: 1.5;
            text-align: center;
            color: #211c51;
            width: 620px;
            text-align: center;
            margin: auto;
            position: relative;
        }
        &__next-button {
            position: absolute;
            right: -70px;
            font-size: 18px;
            line-height: 1.67;
            color: #f8686e;
            cursor: pointer;
            
           
            
        }
    }


    .section {
        display: flex;
        justify-content: center;
        padding-top: 40px;

        &--gray {
            flex-direction: row-reverse;
            background-color: $gray-background;
        }

        &__description {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.56;
            text-align: left;
            color: #211c51;
            width: 220px;
            display: flex;
            align-items: center;
        }

        &__continue-button {
            width: 299px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 36px;
            background-color: #f8686e;
            color: white;
            font-size: 23px;
        }

        &__button {
            padding: 100px 0;
        }
    }

}