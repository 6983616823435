$blue-color: #211c51;





.top-bar {
  height: 58px;
  background-color: white;
  display: flex;
  // width: 47.5%;
  width: 100%;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  // position: absolute;
  // z-index: 2;
  // right: 172px;


  // margin-top: 10px;
  justify-content: space-between;



  a:-webkit-any-link {
    text-decoration: none;
}
  .top-bar-content-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .active-nav-link.active-nav-link.active-nav-link {
      color: #f26167;
    }
    a:-webkit-any-link {
      color: inherit ;
      cursor: pointer;
    }
  
    .active.active.active{
      color: #f26167;
      font-weight: bolder;
    }
    .left-side {
      display: flex;
      align-items: center;
      margin-left: 16px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $blue-color;
      img {
        width: 129px;
        height: 26px;
        cursor: pointer;
      }
  
      .vl {
        border-left: 2px solid #c6c6c6;
        height: 30px;
        margin-left: 15px;
      }
  
      .plan-text {
        color: $blue-color;
        margin-left: 15px;
      }
    }
    .right-side {
      align-items: center;
      color: $blue-color;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
  
      .right-side-ul {
        display: flex;
        list-style: none;
        margin-right: 10px;
        
        li {
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #211c51;
          margin: 0;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 25px;
          position: relative;
          align-items: center;
  
          &:last-child {
          margin-right: 5px;
          }
          &:hover {
            .tooltip {
              display: block;
            }
          }
  
          .tooltip {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            background-color: #5f5f5f;;
            color: white;
            padding: 3px 2px;
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            display: none;
  
  
          }
  
  
          .topbar-tab {
            
          }
          .active, .topbar-tab:hover  {
  
            path {
  
              fill: #f26167 !important;
              transition: all 0.2s;
            }
          }
  
          .help-hover {
            display: none;
            flex-direction: column;
            position: absolute;
            top: 38px;
            background: red;
            width: 124px;
            height: 75px;
            list-style: none;
            padding: 0;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.164);
            background-color: #fff;
            border-radius: 11px;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;
            z-index: 20;
            transform: translateX(-80%);
  
  
            li {
              width: 70%;
              margin: 0;
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              text-align: left;
              color: #4a4a4a;
              display: flex;
              flex-direction: column;
              cursor: pointer;
            }
            .seperator {
              height: 0.5px;
              background-color: #ddd; 
              cursor: default;         
            }
  
            
          }
          .display-flex {
            display: flex;
          }
        }
        .home-image{
          padding-top: 2px;
        }
        .help-image{
        position: relative;
        top: 7px;
        }
        .account-image{
          padding-top: 3px;
        }
        .home-image:hover{
          content: url("../../assets/images/home-hover.svg");
        }
        .help-image:hover{
          content: url("../../assets/images/help-hover.svg");
        }
        .account-image:hover{
          content: url("../../assets/images/account-hover.svg");
        }
        .customize-widget{
    
          border: 2px solid #f8686e;
          color: #f8686e;
          height:30px;
          width:150px;
          text-align: center;
          border-radius: 2px;

          .customize-widget-text{
            position: relative;
            top: 5px;
          }
        }
        .customize-widget:hover{
          background: #f8686e;
          color: white;
        }
        .help-info {
          position: relative;
        }
        .help-info-ul {
          z-index: 9999999;
          margin: 0;
          padding: 0;
          width: 132px;
          list-style: none;
          background-color: white;
          position: absolute;
          top: 46px;
          left: -7px;
          object-fit: contain;
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  
          li {
            padding: 0;
            margin: 0;
            height: 32px;
            padding-left: 14px;
            border-bottom: 1px #c6c6c6 solid;
            display: flex;
            align-items: center;
          }
  
          li:last-child {
            border-bottom: none;
          }
  
         
        }

        .settings-nav {
          display: flex;
        }
      }
    }
  }
}

.top-bar-external {
  // width: 100vw;
  left: 0;
  top: 0;
  position: absolute;
  justify-content: center;
  z-index: 1;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

  .top-bar-content-wrapper {
    width: 748px;
    justify-content: space-between;
    
    .left-side {
      margin: 0;


      .pair-charts-btn {
        background-color: #f8686e;
        color: white;
        padding: 6px 13px;
        border-radius: 3px;
        background-color: #f8686e;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;

        svg{
          margin-right: 5px;
        }


      }
    }
  }
  .reverse{
    flex-direction: row-reverse!important;
    .right-side {
      width: fit-content;
      ul{

        padding-left: 0;
      }
    }
    .left-side{
      width: fit-content;
      margin-right: 20px;
    }
  }


}


.help-modal {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100vw;  
  height: 100vh;
  // background-color: black;  
  // background-color: blue;
  z-index: 14 ; 

}
.display-block {
  display: block;
}

.chain-icon {
  height: 15px !important;
  width: 15px !important;
  margin-right: 10px;
}