.main {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 236);
    height: 100vh;
  
    .comp {
      background-color: red;
      height: 900px;
      width: 700px;
      margin-top: 20px;
      position: absolute;
    }
  }
  
  .size-chart-wrapper {
    position: relative;
    width: 318px ;

    .expand-warning {
      height: 52px;

      border: solid 0.5px rgba(247, 103, 109, 0.55);
      background-color: rgba(247, 103, 109, 0.1);
      padding-right: 5px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      span {
        margin-left: 5px;
        color: #0d66d0;
        a {
          color: inherit;
        }
      }
    }
  }

.product-pair-container {
  display: flex; 
  justify-content: center; 
  // margin-top: 10px;
  max-width: 1039px;
  width: 1039px;
  margin: auto;
  height: 100%;
}

  .size-chart-external{
    margin: auto;
    width: 748px;
    height: 100%;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4); /* <-- this is the culprit */
  }
  

  .select-chart-tip{
      top: 70px;
  }

  