.chart-wizard-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10;
}

.chart-wizard {
  width: 750px;
  border-radius: 8px;
  height: 450px;
  // border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  position: relative;

  .close-button {
    position: absolute;
    height: 25px;
    width: 25px;
    cursor: pointer;
    top: 34px;
    right: 55px;
    z-index: 10;
  }
  .content-container {
    height: 400px;
    width: 100%;
    margin-top: 10px;
  }
  .back-button {
    left: 56px;
    top: 34px;
    position: absolute;
    cursor: pointer;
  }
}

.unable {
  background-color: rgb(177, 179, 179) !important;
  pointer-events: none !important;
}

.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
}
