.store-product-item{
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid #f8f8f8;
    background-color: white;
    margin-bottom: 7px;
    min-height: 61px;
    transition: all 0.2s linear;
    &:last-of-type{
        margin-bottom: 0;
    }
}


.store-product-item-left-section{
    // width: 50%;
    display: flex;
    align-items: flex-start;
    // height: 61px;
    justify-content: flex-start;
    padding-top: 10px;
    height: 50px;

    
.animate {
    height: auto;
    transition: all 1s linear;
}

    img{
        width: 70px;
        // height: 44px;
        object-fit: cover;
        margin-left: 20px;
        max-height: 300px;
        height: 100%;
    }

    p{
        margin-left: 25px;
        object-fit: contain;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        color: #211c51;
        
    }
    input {
        margin-left: 9px;
        margin-top: 15px;
    }

    .control {
        font-family: arial;
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        padding-top: 3px;
        cursor: pointer;
        font-size: 16px;
    }
        .control input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    .control_indicator {
        position: absolute;
        top: 14px;
        left: 18px;
        height: 12px;
        width: 12px;
        background: #ffffff;
        border: 1px solid #c6c6c6;
        border-radius: 0px;
    }
    
    
    .control input:checked ~ .control_indicator {
        background: #f8686e;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        background: #e6647d;
    }
    .control input:disabled ~ .control_indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 4px;
        top: 1px;
        width: 3px;
        height: 6px;
        border: solid #ffffff;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }
    .control-checkbox .control_indicator::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 0rem;
        height: 0rem;
        margin-left: -2.992rem;
        margin-top: -3rem;
        background: #2aa1c0;
        border-radius: 0rem;
        opacity: 0;
        z-index: 99999;
    }
    @keyframes s-ripple {
        0% {
            transform: scale(0);
        }
        20% {
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(1);
        }
    }
    @keyframes s-ripple-dup {
       0% {
           transform: scale(0);
        }
       30% {
            transform: scale(1);
        }
        60% {
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(1);
        }
    }
    .control-checkbox input + .control_indicator::before {
        animation: s-ripple 250ms ease-out;
    }
    .control-checkbox input:checked + .control_indicator::before {
        animation-name: s-ripple-dup;
    }


    .collapsed-photo {
        object-fit: contain;
    }
}
.expanded-height-picture {
    height: 200px;
    margin-bottom: 5px;
    max-height: 105px;
}
    .store-product-item-middle-section {
        margin-left: 22px;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        margin-top: 10px;

        .product-title{
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #211c51;
            margin: 0;
            margin-bottom: 4px;

        }
        .pair-text.pair-text {
            font-weight: normal ;
        }
        .size-chart-pair {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #4a4a4a;
            margin: 0;
            margin-bottom: 5px;

            span {
                font-weight: bold;
                color: #4a4a4a;

            }

            
        }
    }


.store-product-item-right-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 100%;
    margin-right: 10px;


    .pair-unpaor-wrapper {
        margin-bottom: 10px;
        width: 90%;
        margin-top: 10px;
        text-align: left;
    }


    .expand-icon {
        // width: 10px;
        // height: 5px;
        transition: all 0.2s;

    }

    .size-chart-pair{
        text-overflow: ellipsis;
        overflow: hidden;   
        width:133px; // some width
        max-height: 52px;
        white-space: nowrap;

    }

    p{
        width: 125px;
        margin: 0;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #919191;
        white-space: normal;
    }

    .pair-button{
        width: 68px;
        height: 33px;
        background-color: #c6c6c6;
        font-family: Roboto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;


        &.mark{
            background-color: #f8686e;
            color: white;
            cursor: pointer;
            border: none;
        }

        &.selected{
            background-color: white;
            color: #f26167;
            cursor: pointer;
            border:solid 2px #f26167;
        }

    }

    .gray-color.gray-color {
        color: #AFAFAF;
    }
    .eye-expand-wrapper{
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;

        .expanded {
            transform: rotate(180deg);
            transition: all 0.2s;
        }
    }
    .expand-collapse-wrapper {
        padding: 2px 5px;
        cursor: pointer;
    }


    .view-eye-section{
        // width: 60px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;


        p{
            font-size: 16px;

            color: #919191;
        }
    }

    .pair-unpair {
        cursor: pointer;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #f8686e;

    }
}
.MuiTypography-h6{
font-family: Roboto !important;

font-size: 25px !important;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.56;
letter-spacing: normal;
text-align: center;
color: #1f1b45 !important;
font-weight: bolder !important;


// margin-top: 20px !important;
font-weight: bold;
    margin-bottom: -20px !important;
}
.MuiDialogContentText-root{
    font-family: Roboto;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #211c51 !important;
    //width: 390px !important;
    padding-inline: 72px !important;
}
.MuiButton-textPrimary {
    width: 206px;
    height: 45px;
    // margin: auto !important;
    background-color: #fff !important;
    color: #1f1b45 !important;
    border: solid 1px #afafaf !important;
    // margin-bottom: 30px !important;
    // margin: 15px;

    &:hover {
        background-color: #f8686e !important;
        border: solid 1px #f8686e !important;

        transition: all 0.2s linear !important;
        color: white !important;

    }
}
.MuiPaper-root{
    background-color: #f7f7f7 !important;
}


.expand-section {

    .upper-section {
        margin-bottom: 5px;
        div {
            display: inline;
            font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #4a4a4a;
            span {
                font-weight: bold;
            }
        }
    }
    .expanded-list {
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        margin-bottom: 5px;
        span {
            font-weight: bold;
        }
        li {
            display: inline;
            
        }
        
    }
}

.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: block;
    background-color: rgba(0, 0, 0, 0.445) !important;
}


@media only screen and (max-width: 600px) {
    .MuiDialogContent-root {
        width: 100%;
        .MuiDialogContentText-root{
            padding-inline: 0!important;
            padding-block: 20px;
            width: 100%;
        }
    }
}