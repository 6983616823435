
.color-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px #f4f4f4 solid;    
    position: relative;

    &:first-child {
        border-top: 1px #f4f4f4 solid
    }

    .color-text {
        width: 100px;
        height: 20px;
        font-size: 15px;
        line-height: 1.8;
        color: #000000;
        font-size: 12px;
    }

    .color-input {
        -webkit-appearance: none;
        padding: 0;
        border: 1px #c7bebe solid;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;
    }

    .color-picker {
        position: absolute;
        top: 35px;
        left: 125px;
        z-index: 26;
    }

    .color-input::-webkit-color-swatch {
        border: none;
        border-radius: 10px;
        padding: 0;
    }

    .color-input::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 10px;
        padding: 0;
    }

    .color-desc {
        width: 90px;
        text-align: center;
        background: #f4f4f4;
        padding: 5px 10px;
        border-radius: 3px;
        color: #898989;
        font-size: 11px;
        cursor: pointer;
        margin-left: 25px;
    }

    .color-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 25;
        background-color: transparent;
    }
}