#goodbye-page {
  text-align: center;
  width: 100%;

  .image_page {
    display: block;
    margin: 50px auto;
    width: 302px;
    height: 67px;
  }
}

#Uninstall-page {
  padding-top: 30px;
  padding-inline: 30px;
  max-width: 1151px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: auto;
  --dark-blue-grey: #211c51;
  --rosy-pink: #f26167;
  color: var(--dark-blue-grey);

  .image_page {
    height: 36px;
    align-self: flex-start;
  }
  .uninstall-content {
    display: flex;
    gap: 55px;
    margin-block-end: 200px;
    .uninstall-image {
      max-width: 543px;
      img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        background-repeat: no-repeat;
      }
    }
    .uninstall-text {
      display: flex;
      flex-direction: column;
      gap: 23px;
      max-width: 551px;

      > div {
      }
      h2 {
        font-size: 17px;
        font-weight: bold;
        color: var(--dark-blue-grey);
        margin: 0;
        line-height: 30px;
      }
      p {
        line-height: 27px;
        font-size: 15px;
      }
      .uninstall-sorry {
        .text-wrepper__sorry {
          color: var(--dark-blue-grey);
          font-size: 40px;
          font-weight: bold;
          margin: 0;
        }
        ul {
          padding-inline-start: 20px;
          margin-block-end: 0;
          li {
            line-height: 30px;
            font-size: 16px;
            &::marker {
              font-size: 20px;
              color: var(--dark-blue-grey);
            }
          }
        }
      }
      .uninstall-middle {
        border-top: 10px solid #ffffff;
        border-bottom: 10px solid #ffffff;
        display: flex;
        align-items: flex-start;
        padding-block: 25px;
        gap: 17px;
        img {
          width: 30.5px;
          transform: translateY(8px);
        }
        &-container {
          h2 {
          }
          p {
            max-width: 80%;
            margin-block-end: 0;
          }
        }
      }

      .uninstall-survey {
        display: flex;
        flex-direction: column;
        gap: 22px;
        h2 {
          a {
            text-decoration: underline;
            color: inherit;
            font-size: inherit;
            font-weight: normal;
          }
        }
        &-options {
          display: flex;
          flex-direction: column;
          gap: 18px;
          .survey-option {
            display: flex;
            align-items: center;
            // gap: 8px;
            cursor: pointer;
            > * {
              cursor: inherit;
            }
            input {
              margin: 0;
              &[type="radio"] {
                -webkit-appearance: none;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                outline: none;
                border: 1px solid #afafaf;
              }

              &[type="radio"]:before {
                content: "";
                display: block;
                width: 60%;
                height: 60%;
                margin: 20% auto;
                border-radius: 50%;
              }

              &[type="radio"]:checked:before {
                background: var(--rosy-pink);
              }

              &[type="radio"]:checked {
                border-color: var(--rosy-pink);
              }

              &.checked {
                ~ label {
                  font-weight: bold;
                }
              }
            }
            label {
              color: var(--dark-blue-grey);
              font-size: 16px;
              white-space: break-spaces;
              padding: 0 0 0 8px;
              max-width: 80%;
            }
          }
        }
        .reason-text-area {
          resize: none;
          height: 100px;
          padding: 10px;
          font-family: inherit;
          border-radius: 3px;
        }
      }

      .send-button {
        display: grid;
        place-content: center;
        width: 100%;
        height: 60px;
        background-color: gray;
        font-size: 19px;
        font-weight: bold;
        color: #fff;
        border-radius: 3px;
        border-color: transparent;
        cursor: not-allowed;
        &.available {
          cursor: pointer;
          background-color: var(--rosy-pink);
        }
      }
    }
  }
}

.uninstall-thank-you {
  padding: 50px;
  &__logo {
    height: 70px;
  }

  &__feedback {
    font-weight: bold;
  }
}

@media only screen and (max-width: 800px) {
  #Uninstall-page {
    .uninstall-content {
      gap: 5%;
    }
  }
}

@media only screen and (max-width: 600px) {
  #Uninstall-page {
    padding-inline: 10px;
    .uninstall-content {
      flex-direction: column;
      gap: 20px;
      > div {
        max-width: unset !important;
      }
      .uninstall-image {
        img {
          object-fit: cover;
          object-position: 50% 10%;
          width: 100%;
          height: 40vw;
        }
      }
    }
  }
}
