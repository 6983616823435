.book-meeting {
  color: #211c51;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  position: relative;
//   widt÷h: 950px;


  a:-webkit-any-link {
    text-decoration: none;
  }


  &__arrow-left {
      transform: rotateY(180deg);
      cursor: pointer;
      position: absolute;
      left: -300px;
      top: 15px;
  }
  &__title {
    font-size: 40px;
    font-weight: bold;
    margin-top: 90px;
    position: relative;
  }
  &__description {
    line-height: 1.5;
    font-size: 20px;
    margin-top: 20px;


  }

  &__loader {
    margin: auto;
    position: absolute;
    right: 47%;
    top: 50%;
    transform: translate(-50%, -50%);

  }


}
.all-set {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #211c51;

  &__title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }
  &__description {
    font-size: 20px;
    line-height: 1.5;

  }
  &__button {
    width: 299px;
    height: 62px;
    border-radius: 36px;
    background-color: #f8686e;
    color: white;
    font-size: 23px;
    line-height: 1.52;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
