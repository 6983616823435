$widget-gray: rgb(156, 154, 154);

.advanced-widget-preview-frame {
    display: flex;
    flex-direction: column;
    align-items: center;

    .advanced-widget-preview {
        background: #f4f4f4;
        width: 350px;
        border-radius: 12px;
        width: 344px;
        height: 321px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px;

        .widget {
            width: 291px;
            height: 251px;
            box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.16);
            
            &__header {
                margin: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &__logo {
                height: 17px;
            }

            &__gender {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                margin-top: 20px;

                &-header {

                    color: $widget-gray;
                    position: absolute;
                    left: 0;
                    top: -12px;
                }

                &-item {
                    border: 2px $widget-gray solid;
                    color: $widget-gray;
                    width: 45%;
                    text-align: center;
                    padding: 8px 0;
                    position: relative;

                    &--active {
                        color: black;
                        border-color: black;
                    }
                }
            }

            &__title {
                font-size: 8px;
                font-weight: bold;
                color: #211c51;
                font-size: 10px;
            }

            &__close {
                height: 6px;
            }

            &__body {
                margin-top: 10px;
                font-weight: bold;
                color: $widget-gray;
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;

                &-item {
                    width: 45%;
                
                    &-title {
                        margin-bottom: 5px;
                    }

                    &-number {
                        border-bottom: 1px solid $widget-gray ;
                        text-align: center;
                        margin-bottom: 5px;

                    }

                    &-system {
                        text-align: right;
                        span {
                            color: black;
                        }
                    }
                }
            }

            &__show-size {
                height: 25px;
                background-color: black;
                color: white;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
            }

            &__have {
                color: black;
                font-weight: bold;
                text-align: center;
            }

            &__social {
                display: flex;
                padding: 0 10px;
                justify-content: space-between;
                margin-top: 6px;
                
                &-item {
                    height: 30px;
                    width: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    -webkit-box-shadow: 0px 8px 29px -9px rgba(0,0,0,0.89); 
                    box-shadow: 0px 8px 29px -9px rgba(0,0,0,0.89);
                    margin-bottom: 5px;

                    img{
                        height: 15px;

                    }
                }
            }

            &__footer {
                font-size: 7px;
                color: $widget-gray;
                text-align: center;

                span {
                    text-decoration: underline;
                }
            }
        }

        &__reset-button {
            width: auto;
            font-size: 15px;
            line-height: 1.73;
            color: #898989;
            margin-left: 15px;
            cursor: pointer;
            
            p {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}