.store-prduct-container{
    // width: 658px;
    // padding: 13.7px 22px;
    //border: 1px solid black;
    // background: white;
    height: calc(100% - 60px);
    box-sizing: border-box;
    margin-top: 5px;

    .product-filter-items-wrapper{
        // width: 712px;
        margin: 4px 0;
        padding: 7px 14px 6px 8px;
        background-color: #fff;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .filter-img-section{
            width: 70.4px;
            height: 27px;
            background-image: url('../../assets/images/filter-not-clicked.svg');
            margin-left: 30px;
            cursor: pointer;
            position: relative;
        }

        .filter-img-section:hover{
            background-image: url('../../assets/images/filter-clicked.svg');
        }

    
    .filter-item-list{
    padding: 3px 22.6px 5px 11px;
    border-radius: 3px;
    border: solid 1px #afafaf;
    object-fit: contain;
    font-family: Roboto;
    font-size: 14px;
    text-align: left;
    color: #4a4a4a;
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 4px;
    position: relative;
    cursor: pointer;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width:300px;

    .delete-filter-img{
        display: none;
    }
    }

    .filter-item-list:hover{
        .delete-filter-img{
            display: block;
            position: absolute;    
            cursor: pointer;
            right: 5px;
            top: 6px;
    
        }
    }
}

    .modal-product {
        z-index: 50000;
        position: fixed;
        height: 100vh;
        width: 100vw;
        // background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
      }

    
    .shop-product-pagination{
        display: flex;
        justify-content: center;
        position: absolute;
        left: 40%;
        bottom: -22px;

        .pagintation-product{
            display: flex;
            align-items: center;
            cursor: pointer;
            
        }

    }

}

.store-product-header{
    object-fit: contain;
    font-family: Roboto;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    color: #211c51;
}

.loading-indicator {
    height: calc(100vh - 338px);
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-product-list{
    // height: calc(100vh - 338px);
    // overflow-y: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;



    .modal-product {
    z-index: 50000;
    position: fixed;
    height: 100vh;
    width: 100vw;
    // background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
  }



    .no-product-p{
        padding-top: 50px;
        height: 21px;
        object-fit: contain;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #919191;
      } 
      
      .search-wrapper{
        display: flex;
        justify-content: flex-start;
        position: relative;
    }
    .filter-button{
      padding: 12.5px 28px;
      object-fit: contain;
      background-color: #211c51;
      cursor: pointer;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .filter-select {
        // width: 10%;
        height: 100px;
        background-color: #f5f5f5;
        width: 93px;
        padding-left: 10px;
        font-size: 15px;
    
    }
    .search-selector{
        background-color: #f5f5f5;
        border-radius: 5px;
        margin-right: 5px;
        color: #211c51;
        width: 93px;
        border: solid 1px #afafaf !important;
        padding-left: 10px;
        
      
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        
    
    }
    .filter-down-arrow{
        height: 5px;
        top: 20px;
    
        transform: translateY(-50%);
        left: 70px;
        position: relative;
    }
    
    
    .filter-input{
        width: 450px;
        height: 39px;
        border: solid 1px #afafaf !important;
        padding-left: 30px;
        border-radius: 5px 0px 0px 5px;
        box-sizing: content-box;
    }
    .search-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 110px;
    }
    .filter-input:focus{
        outline: none;
    }
    
}