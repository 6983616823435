.widget-font-settings {
    width: 100%;

    &-header {
        display: flex;
        position: relative;
    
        &-image {
            margin-left: 10px;
        }
    
        &-label {
            font-size: 15px;
        }
    
        .widget-font-settings-info-bubble {
            width: 320px;
            height: auto;
            background: white;
            padding: 20px;
            color: #f8686e;
            font-size: 15px;
            border-radius: 7px 7px 7px 0px;
            position: absolute;
            bottom: 60%;
            left: 55px;
            box-shadow: 0px 0px 20px #b6b6b6;
            font-size: 15px;
            z-index: 26;
        }
    }
    
    .widget-font-settings-input-fields {
        display: flex;
        flex-direction: column;
    
        .widget-font-settings-input-field {
            width: 100%;
            border: 1px #b1b0b0 solid;
            background: white;
            border-radius: 20px;
            padding: 7px 0px 7px 20px;
            margin-bottom: 15px;
            font-size: 14px;
        }
    
        .widget-font-settings-input-field:focus {
            width: 100%;
            background: white;
            border-radius: 20px;
            padding: 7px 0px 7px 20px;
            margin-bottom: 15px;
            font-size: 14px;
            outline-color: #f8686e;
            outline-width: 2px;
        }
    
        .css-ahj2mt-MuiTypography-root {
            font-size: 14px !important;
        }
    
        input::placeholder {
            color: #b1b0b0;
            font-size: 14px;
        }
    }
}