


.widget-logo {
    background-color: white;
    width: 300px;
    // padding-left: 20px;
    border-bottom: 1px #f4f4f4 solid ;

    .header {
    
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    
    
        &__title {
    
            display: inline-block;
            font-size: 15px;
            line-height: 1.67;
            color: #000;
        }
        
        .switch {
            position: relative;
            display: inline-block;
            width: 31px;
            height: 19px;
        
        
            input {
                opacity: 0;
                width: 0;
                height: 0;
          }
        
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
          }
        
        
          .slider:before {
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          input:checked + .slider {
            background-color: #f8686e;
          }
          
          input:focus + .slider {
            box-shadow: 0 0 1px #f8686e;
          }
          
          input:checked + .slider:before {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
          }
          
          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }
          
          .slider.round:before {
            border-radius: 50%;
          }
          
        
        }
    }


    .icons-container {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;

      &__item{
        
        &--shadow{
          // box-shadow: 5px 5px #888888;
          cursor: pointer;
        } 

      }
    }
}
