.x-button{
    position: absolute;
    right: 100px;
    top: 35px;
    height: 25px;
    width: 25px;
    &:hover {
        cursor: pointer;
    }
}

.dialog-content-lock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    img {
        height: 56px;
        width: 49px;
        margin-bottom: 10px;
    }
    span {
        margin-top: 10px;
        font-family: Roboto!important;
        font-size: 31px!important;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #211c51;
        min-width: 400px;
    }
}

.dialog-lock {
    .MuiDialog-paperWidthSm {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 230px 216px !important;
        max-width: 1040px!important;
        margin-top: 100px !important;
    }
}


