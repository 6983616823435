$mysize-pink: #f26167;

// .completed.completed.completed {
//   background-color: $mysize-pink;
// }

// .completed{

//     background-color: $mysize-pink !important;
//   .RFS-Connector{
//       border-color: $mysize-pink !important;
//   }
// }

// .RFS-StepCircleContent {
//   display: none;
// }

// .RFS-StepCircle.RFS-StepCircle .completed.completed {
//   background-color: $mysize-pink;
// }

.RFS-StepperContainer {
  width: 389px;
  padding: 0;
}

.RFS-StepMain {
  display: flex;
  flex-direction: column-reverse !important;
  align-items: center !important;
}

.RFS-Label {
  margin: 0 !important;
  padding-bottom: 7px !important;
  font-family: Roboto;
  font-size: 16px;
//   font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #afafaf;
}

.RFS-Label .completed {
    color: $mysize-pink;
}

.RFS-ConnectorContainer {
  top: 34px !important;
  width: 62px !important;
  left: -31px !important;
}

.RFS-StepCircle {
  height: 17px !important;
  width: 17px !important;
  z-index: 20 !important;
  &.completed {
    background-color: #f26167 !important;
    &.RFS-Connector {
      border-color: red !important;
    }
  }
  &.active {
    background-color: white !important;
    border: 2px solid #f26167;
  }
}

.RFS-Connector {
  &.active,
  &.completed {
    border-color: red !important;
    border-top-width: 2px !important;
  }
}

.RFS-StepCircleContent {
  display: none;
}


.active-name {
    
        color: $mysize-pink !important;
        font-weight: bolder !important;
    
}
.RFS-StepButton {
  cursor: default !important;
}

.passed {
  color: #f26167;
  font-weight: 400;
}