.footer {
    font-size: 15px;
    font-weight: normal;
    line-height: 2.2;
    color: #848484;
    margin: 0;
    font-family: Roboto;

    a, a:visited{
        color: #848484 !important;
        text-decoration: underline;
        font-weight: normal !important;
        font-family: inherit;
    }
}