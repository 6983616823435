
.paired-product-alert-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    // background-color: rgba(0, 0, 0, 0.479);
    z-index: 50;

    .paired-product-alert-modal{
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.308);
    }

    .paired-product-alert {
        max-width: 510px;
        padding: 37px 55px;
        background-color: #f7f7f7;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        box-sizing: border-box;
    
        p {
            object-fit: contain;
            font-family: Roboto;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: normal;
            text-align: center;
            color: #211c51;
            margin-bottom: 5px;
        }
        .question {
            font-weight: normal;
            font-style: italic;
            font-size: 16px;
            margin: 0;
    
    
        }
        .pair-list {
            object-fit: contain;
            font-family: Roboto;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.08;
            letter-spacing: normal;
            text-align: left;
            color: #848484;
            padding: 0 20px;
            max-height: 200px;
            overflow-y: scroll;

            span {
                font-weight: bold;
            }
        }
        .replaced-chart-text {
            font-weight: normal;
            font-size: 16px;
        }


        .buttons-wrapper {
            display: flex;


            div {
                width: 160px;
                height: 49px;
                margin: 31.5px 6px 0 31px;
                border: solid 1px #afafaf;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Roboto;
                font-size: 19px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.58;
                letter-spacing: normal;
                text-align: center;
                color: #1f1b45;
                cursor: pointer;
            }
        }
    
    
    }

}