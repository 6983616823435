.size-chart-error-container{
  width: 847px;
  height: 72px;
  padding: 11px 13px 19px 48px;
  object-fit: contain;
  border: solid 1px #f26167;
  background-color: rgba(242, 97, 103, 0.27);
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &.can-submit{
    border: solid 1px rgba(68, 201, 137, 0.29) !important;
    background-color: rgba(68, 201, 137, 0.29) !important;
  }


  .text-para-error{
  width: 544px;
  margin: 0 60.8px 0 19px;
  object-fit: contain;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #211c51;
    }

    .buttons-section{
        // height: 21px;
        display: flex;
        align-items: center;
        p{

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #898989;
        }
    }

    img{
        cursor: pointer;
    }


}