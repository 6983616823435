.widget-settings-body {
    background: white;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    height: 100vh;

    .widget-preferences {
        width: 260px;
        display: flex;
        padding: 0px 30px;
        flex-direction: column;
        align-items: center;
        margin-left: 70px;

        .widget-settings-component {
            width: 100%;
        }

        .widget-settings-buttons-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .widget-settings-save-button {
                width: 230px;
                margin-top: 20px;
                color: white;
                background: #f8686e;
                padding: 10px;
                border-radius: 20px;
                text-align: center;
                cursor: pointer;
                height: 18px;
                max-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;

                &--disabled {
                    background-color: #b7b7b7;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
        
            .widget-settings-go-to-button {
                width: 100%;
                margin-top: 25px;
                display: flex;
                justify-content: left;
                cursor: pointer;
                justify-content: space-between;
                color: black;
                border-bottom: 1px solid #f4f4f4;
                padding-bottom: 10px;
             

                &-text {
                    font-family: Roboto;
                    font-size: 15px;
                    margin-right: 15px;
                }
            }
        }
    }

    .widget-preview {
        width: 200px;
        margin-right: 100px;
    }

    p{
        margin-top: revert;
        margin-bottom: revert;
    }
}