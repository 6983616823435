.screen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    display: grid;
    background-color: #fff;
    opacity: 0.5;
    place-content: center;
    cursor: not-allowed;
}
.size-chart-item {

 .seperator {
     display: none;
 }

}



.size-chart-item-external {
    position: relative;

    .delete-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

    }
    .integration-code-container {
        width: 151px;
        height: 30px;
        border-radius: 3px;
        background-color: #eaeaea;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #211c51;
        font-size: 14px;
        position: absolute;
        right: 50px;
        fill: #211c51;
        cursor: text;

        .integration-code-wrapper {
            margin-left: 5px;
            margin-left: 5px;
        }
       
    
    }
    .seperator {
        display: block;
        width: 1px;
        background-color: rgb(219, 217, 217);
        height: 140%;
        margin-right: 15px;

    }
    .type-image {
        margin-top: 17px;
    }
}


.reverse {
    flex-direction: column-reverse !important;
    margin-top: 0 !important;
    justify-content: center;
}