.size-matrix {
  // background-color: hotpink;
  font-size: 18px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  // width: fit-content;
  position: relative;
  background-color: white;
  border: 1px solid #c6c6c6;
  // border-top: none;
  padding-bottom: 10px;
  max-height: 217px;
  overflow: scroll;
  // max-width: 700px;
  min-height: 60px;
  

  .w-l {
    position: absolute;

}
.w{
  left: 49px;
  top: 7px;
}
.l{
  top: 35px;
  left: 27px;

}
  


  .width-title {
    border-bottom: solid 1px rgb(214, 214, 214);
    padding: 0;
    margin-bottom: 10px;
    padding-bottom: 15px;
    padding-left: 55px;
    width: fit-content;
    min-height: 50px;
    min-width: 35px;

  }
  .width-row {
    display: flex;
    list-style: none;
    // border-bottom: solid 1px black;
    margin-left: 80px;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 50px;
    font-size: 15px;

    .width-row-li {
      // margin-left: 10px;
      width: 70px;
      padding-right: 10px;
      text-align: center;
      overflow: hidden;
      margin-top: 30px;

    }
  }

  .heigth-column {
    display: flex;
    width: 90px;
    flex-direction: column;
    border-right: solid 1px rgb(214, 214, 214);
    list-style: none;
    position: absolute;
    top: -24px;
    left: -20px;
    // padding-left: 60px;
    padding-top: 72px;
    vertical-align: middle;
    padding-left: 0;
    margin-left: 20px;

    .heigth-column-li {
      height: 44px;
      margin-bottom: 10px;
      text-align: center;
      // padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .matrix-grid {
    position: relative;
    grid-gap: 10px;
    display: grid;
    // top: 20px;
    left: 103px;

    .checker {
      border: solid 0.8px #f8686e;
      cursor: pointer;
      color: #6b6b6b;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      //box-shadow: 0px 0px 4px #f8686e;
    }
  }

  .heigth-title {
    position: absolute;
    left: 10px;
    top: 45px;
  }
  .heigth-content {
    height: 100%;
    padding: 10px 0;
  }

  .check-icon {
    width: 20px;
    height: 15px;
  }

  .disabled {
    //box-shadow: 0px 0px 4px #c4c4c4 !important;
    border: solid 0.8px #c4c4c4 !important;
  }
  .slant-line{
    width: 110px;
    height: 41px;
    border-bottom: 1px solid #c6c6c6;
    -webkit-transform: translateY(-5px) translateX(2px) rotate(
                    36deg
    );
    position: absolute;
    z-index: 1000;
  }
}
