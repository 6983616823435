.tx-modal {
    --timeout: var(--timer);
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    &.active{
      display: grid;
      place-content: center;
      background-color: var(--bgc-screen)!important;
      padding: 0;
      animation: fade-out 1s ease calc(var(--timeout) - 2500ms) forwards;
      .modal-content{
        display:grid;
        place-content: center;
        z-index: 1001!important;
        user-select: none;
        padding: 57px 63px;
        // width: 50vw;
        // height: 50vh;
        background-color: var(--bgc-msg);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.21);
        text-align: center;
        border-radius: var(--border-radius);
        position: relative;
        opacity: 0;
        font-family: Roboto;
        animation: fade-in 1s ease 0.5s forwards,
            fade-out 1.5s ease-in-out calc(var(--timeout) - 3000ms) forwards;
        h1{
            color: var(--title-clr);
            margin: 0;
        }
        p{
            color: var(--body-clr);
            white-space: pre;
            font-size: 16px;
            margin: 10px 0 0;
            padding: 0 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
        }
        .close-btn, .x-btn {
            cursor: pointer;
            padding: 10px;
            border: 0;
        }
        .close-btn{
            background-color: var(--btn-bgc);
            color: var(--btn-text);
            font-weight: bold;
            margin-top: 20px;
            transition: 0.5s;
            &:hover{
                filter: brightness(85%);
            }
        }
        .x-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            color: var(--x-color);
            background-color: transparent;
        }

        @keyframes fade-in {
            to {
                opacity: 1;
            }
        }
        @keyframes fade-out {
            to {
                opacity: 0;
            }
        }
      }
    }
  }