.thank-you-wrapper {
    
    padding-top: 10px;
  
}

.thank-you {
  width: 611px;
  height: 471.7px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 8px;


  .up-dotted-border {
    position: absolute;
    top: 37.2px;
    left: 44.3px;
  }
  .bottom-dotted-border {
    position: absolute;
    bottom: 47.7px;
    right: 38.9px;
  }
  .content {
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    // width: 349px;
    padding-top: 20px;

    img {
      width: 50px;
      height: 37px;
      margin-bottom: 25px;
      margin-top: 55px;
    }
    h1 {
      color: #211c51;
      line-height: 1.52;
      letter-spacing: normal;
      object-fit: contain;
      font-family: Roboto;
      font-size: 44px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      margin: 0;
      margin-bottom: 10px;
    }
    p {
      object-fit: contain;
      font-family: Roboto;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1f1b45;
      margin: 0;
      margin-bottom: 25px;
    }
    .done-btn {
      width: 232px;
      height: 60px;
      background-color: #f8686e;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: 19px;
      color: #ffffff;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      cursor: pointer;
    }
  }
}