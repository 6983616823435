$mysize-pink: #f26167;
$mysize-blue: #211c51;
;



.forgot-password-container {
    width: calc(457px + 457px + 65px);
    margin: auto;
    padding-top: 41px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .auth-modal {
        display: block;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba(247, 247, 247, 0.671);
        display: flex;
        align-items: center;
        justify-content: center;
 
     }
    
 
    

    .forgot-password {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: normal;
        text-align: left;
        color: #f26167;
    }

    .left-side {
        display: flex;
        flex-direction: column;
        width: 457px;

        h3 {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $mysize-blue;
        }
        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            label{
                position: absolute;
                top: 10px;
                left: 20px;
                transition: .2s;
                user-select: none;
                color: #848484;
            }
    
            input:focus ~ label, label.focused {
                top: 5px;
                font-size: 10px;
                transition: .2s;
            }

            input {
                height: 48px;
                margin-bottom: 15px;
                border-radius: 3px;
                background-color: #fff;
                border-width: 0;
                padding-left: 20px;
                box-sizing: border-box;
                line-height: 48px;
                padding-top: 10px;
                
                &:focus {
                    outline: none;
                }
            }

            .measurement-unit {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                p {
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #848484;
                    margin-right: 10px;
                }
                .switcher-container {
                    display: flex;

                    div {
                        width:  77px;
                        height:  48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3px;
                        background-color: #fff;
                        margin-left: 7px;
                        cursor: pointer;
                        font-size: 19px;
                        font-weight: bold;

                        color: #aaa;
                    }


                    .active {
                        border: 1px solid $mysize-pink;
                        color: $mysize-pink;
                        box-sizing: border-box;
                    }

                }
            }

            .update-container{
                margin-top: 21px;
                input{
                    display: inline-block;
                    margin: 0;
                    height: 13px;
                    

                }
                
                p{
                    display: inline-block;
                    margin: 0;
                    margin-left: 10px;
                    font-size: 16px;
                    color: #848484;
                }
            }
            .signup-button {
                width: 100%;
                height: 60px;
                background-color: $mysize-pink;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 19px;
                font-weight: bold;
                color: #fff;
                margin-top: 32px;
                cursor: pointer;
                border-radius: 3px;
                border: none;
            }

            

                a, a:visited {

                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #f26167;
                    text-align: center;
                }
            

            .signin-container {
                color: $mysize-blue;
                text-align: center;  
                font-weight: 600;   
                
                a {
                    color: $mysize-pink;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
         
           
            .forgot-password {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: normal;
                text-align: left;
                color: $mysize-pink;
            }
            .red-border {
                border: 2px #f26167 solid;
            }

            .error {
               
                // width: fit-content;
                // position: absolute;
                // top: 24px;
                // transform: translateY(-50%);
                // right: 0;
                // color: $mysize-pink;
                // margin-right: 10px;
                position: absolute;
            // top: 17px;
            // top: calc(50% - 13px);
            transform: translateY(-25%);
            // right: 0;
            color: $mysize-pink;
            margin-right: 50px;
            font-weight: bold;
            user-select: none;
            font-size: 12px;
            top: 55px;
            left: 20px;
            }
        }
    }
}
@media only screen and (max-width: 1019px) {
    .forgot-password-container {
        padding-top: 0;
        width: 100%;
        justify-content: center;
        .left-side {
            h3 {
                padding: 18px 0;
                margin-bottom: 0;
            }
        }
        .right-side {
            display: none;
        }
    }

    *::-webkit-scrollbar {
        width: 0;
    }
}

@media only screen and (max-width: 599px) {
    .forgot-password-container {
        .left-side {
            width: 320px;
        }
    }
}

@media only screen and (max-width: 399px) {
    .forgot-password-container {
        .left-side {
            width: 300px;
        }
    }
}
