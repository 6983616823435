.size-chart-creator-table-header {
    width: 847px;
    height: 61px;
    padding: 18px 29px;
    box-sizing: border-box;
    background-color: #211c51;
    color: white;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .size-chart-breadcrumbs {
      display: flex;
      align-items: center;
    }

    .is-edited{
      cursor: pointer;
      overflow: hidden;
      // max-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      // width:200px; // some width
      max-width: 200px;
    }
    .is-edited:hover{
      color: #f26167
    }

    .size-chart-name {
      font-weight: bold;
      font-family: Roboto;
      padding-right: 20px;

      a:-webkit-any-link {
        color: inherit;
      }


    }

    .edit-container {
      background-color: white;
      z-index: 20;
      padding: 60px 40px;
      border-radius: 8px;
    }

    .edit-modal {
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.247);
      z-index: 15;
    
    }


    .edit-container{
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 8px;
    }
  }


