.size-chart-uploading-wrapper {
  // padding-top: 116px;
  // margin-top: 60px;
}

.size-chart-uploading {
  width: 600px;
  // height: 627px;
  border-radius: 10px;
  margin: auto;
  background-color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 60px;
  position: relative;
  z-index: 15;

  .back-arrow {
    display: none;
    position: absolute;
    left: 59px;
    top: 52px;
    cursor: pointer;
  }
  .x-button {
    height: 15px;
    width: 15px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  h1 {
    width: 500px;

    object-fit: contain;
    font-family: Roboto;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: left;
    color: #211c51;
    padding: 0;
    margin: 0;
  }

  p {
    // width: 477px;
    // height: 30px;
    // margin: 6px 0 18px 223.5px;
    object-fit: contain;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f1b45;
    margin: 0;
    margin-bottom: 18px;
  }

  .drop-down {
    height: 120px;
    // margin: 18px 23px 30px 221.5px;
    // padding: 33px 99px 40px 94px;
    object-fit: contain;
    border-radius: 7px;
    border: dashed 2px rgba(242, 97, 103, 0.5);
    background-color: rgba(242, 97, 103, 0.03);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .drop-down-content-wrapper {
      display: flex;
      justify-content: center;

      img {
        margin-right: 22px;
      }
      p {
        margin: 0;
        margin-bottom: 7px;
        margin-top: 7px;
        padding: 0;
        object-fit: contain;
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #b2b2b2;

        span {
          color: #f26167;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .file-name {
        color: #211c51;
      }
    }
  }
  form {
    // height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;

    input,
    textarea {
      object-fit: contain;
      border: solid 1px #d0d0d0;
      background-color: #ffffff;
      height: 48px;
      margin-bottom: 11px;
      padding-left: 13px;
      font-size: 16px;
      color: black;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      font-family: "Roboto";
    }

    label {
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #211c51;
      padding-bottom: 4px;
    }

    .text-right {
      text-align: right;
      position: absolute;
      right: 0;
    }

    .input-email-wrong {
      border: solid 1px red;
      border-radius: 5px;
    }

    textarea {
      padding-top: 14px;
      padding-bottom: 0;
      resize: none;
    }

    .description-input {
      height: 46px;
      padding-bottom: 20px;
      margin-top: 20px;
    }
    .submit-button {
      height: 60px;
      width: 232px;
      background-color: #f8686e;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      color: #ffffff;
      font-family: Roboto;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      cursor: pointer;
      user-select: none;
      &.disabled{
        pointer-events: none;
        cursor: not-allowed;
        background-color: #d1d1d1;
      }
    }
    .pink-button {
      background-color: #f8686e;
    }

    p {
      margin-bottom: 11px;
      object-fit: contain;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      color: #211c51;
    }
  }
  .email-error {
    color: red;
    font-size: 10px;
    position: absolute;
    top: 142px;
    left: 0;
    font-style: italic;
    margin-top: 15px;
  }

  .error-message {
    color: red;
    font-size: 15px;
    font-weight: 400;
  }
}
