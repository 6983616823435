//.chart-list-wrapper{
//    width: 384px;
//    height: 200px;
//    border:  1px solid black;
//    padding: 6px;
//}
//
//.chart-list-header{
//    font-family: Roboto;
//    font-size: 25px;
//    font-weight: bold;
//    text-align: center;
//    color: #1f1b45;
//}
//
//.close-button{
//    cursor: pointer;
//
//}
//
//.img-wrapper{
//    text-align: right;
//}

.size-chart-external {
  .size-chart-list-header {
    // width: calc(100% - 15px) !important;
    width: 100% !important;
    .popping-div {
      left: 0;
      top: 120px;
    }
  }
  .external-dashboard-title {
    height: 26px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #211c51;
    display: flex;
    justify-content: center;
    align-items: center;

    .view-products {
      display: none;
    }
  }

  .show-view-products {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;

    .view-products {
      display: flex;
      margin-right: 15px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #211c51;
      cursor: pointer;

      img {
        margin-right: 8px;
      }
    }
  }

  .exist-size-chart-list {
    .charts-review {
      width: 730px;
      max-width: 733px;
      height: 58px;
    }
  }
  

  .size-chart-tip {
    // width: 733px;
    position: relative;

    &__info {
      // margin-top: 10px;
      margin-top: 0;
      align-self: unset;
    }

    &__body {
      p {
        width: auto;
      }
    }

    &__arrow-container {
      position: absolute;
      right: 110px;
     bottom: 0;
    }
  }
}

.exist-size-chart-list {
  // padding-top: 15px;
  display: flex;
  flex-direction: column;
  max-height: 89vh;
  height: 100%;
  padding-right: 15px;

  .popping-div {
    position: absolute;
    background-color: #f8686e;
    top: 80px;
    left: 270px;
    width: 296px;
    height: 158px;
    background-color: white;
    border-radius: 11px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #211c51;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    z-index: 15;

    p {
      margin-top: 14px;
      margin-bottom: 20px;

    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      padding-left: 10px;
      width: 88%;

      li {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        padding-left: 10px;
        padding: 4px 0;
        cursor: pointer;
        &:hover {
          background-color: #f4f4f4;
        }
      }
      .seperator {
        height: 1px;
        background-color: #4a4a4a18;
        width: 100%;
      }
    }
  }

  .charts-review {
    // width: 363px;
    max-height: 318px;
    height: 80px;
    width: 300px;
    max-width: 300px;
    background-color: #f6dedf;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 2px solid #f8686e;
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    color: #d5252c;
    display: flex;
    align-items: center;
    // padding: 10px 20px;
    // display: none;

    .charts-review-text {
      font-family: Roboto;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      // line-height: 3.70;
      letter-spacing: normal;
      // text-align: left;
      color: #d5252c;
      padding-right: 20px;
      padding-left: 20px;
    }
    .hourglass-icon {
      // float: left;
      // margin-top: 22px;
      margin-left: 22px;
    }
  }

  .header {
    object-fit: contain;
    font-family: Roboto;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #211c51;
    background-color: white;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    // width: 331px;
    margin: 0 0 5px 0;

    .size-charts-title {
      font-size: 20px;
    }
    div {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
      }

      .create-chart-modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        z-index: 14;
      }
      .popping-div {
        position: absolute;
        background-color: #f8686e;
        top: 80px;
        left: 270px;
        width: 296px;
        height: 158px;
        background-color: white;
        border-radius: 11px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #211c51;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 16px;
        z-index: 15;

        p {
          margin-top: 14px;
          margin-bottom: 20px;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          padding-left: 10px;
          width: 88%;

          li {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: left;
            color: #4a4a4a;
            padding-left: 10px;
            padding: 4px 0;
            cursor: pointer;

            &:hover {
              background-color: #f4f4f4;
            }
          }
          .seperator {
            height: 1px;
            background-color: #4a4a4a18;
            width: 100%;
          }
        }
      }
    }
  }

  .exist-size-chart-search {
    width: 300px;
    height: 39px;

    object-fit: contain;
    border-radius: 3px;
    border: solid 1px #afafaf;
    background-color: #ffffff;
    box-sizing: border-box;
    padding-left: 30px;
  }
  .search-icon-size-charts {
    position: absolute;
    margin-top: 11px;
    transform: translateX(70%);
  }

  .size-chart-creation {
    width: 363px;
    height: 60px;
    background-color: #f26167;
    margin: 16px 0;
    border: none;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }

  .create-size-chart-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000079;
    z-index: 10;
  }
  .popup-size-chart-creator {
    position: fixed;
    z-index: 15;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .generic-position {
    transform: none;
  }

  .size-chart-list-header {
    display: flex;
    justify-content: space-between;
    width: 303px;
    margin-bottom: 4px;
    cursor: pointer;
  }
}

.size-chart-item-card {
  box-sizing: border-box;
  // width: 363px;
  height: 110px;
  padding: 15px 19px 19px 15.8px;
  object-fit: contain;
  //border: 1px solid black;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  border: 2px solid white;
  background: white;
  margin-bottom: 3px;
  cursor: pointer;
  position: relative;
  padding-left: 5px;
  // margin-right: 5px;
  border-radius: 5px;

  .type-image {
    width: 55px;
    //height: 70px;
    // margin-bottom: 40px;
    margin-top: 5px;
  }

  .vl {
    border-left: 1px solid #dad8d8;
    height: 50px;
    margin-right: 15px;
  }

  .chart-info-section {
    display: flex;
    flex-direction: column;
    width: 250px;
    margin-top: 22px;
    height: 62px;

    h1 {
      width: 200px !important;
      font-family: Roboto;
      font-size: 17px;
      font-weight: bold;
      text-align: left;
      color: #211c51;
      margin: 0;
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 52px;
      // white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    p {
      font-family: Roboto;
      font-size: 15px;
      text-align: left;
      color: #969696;
      margin: 0;
    }
  }

  .edit-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .edit-icon:hover {
    content: url("../../assets/images/edit-icon-hover.svg");
  }
  .delete-icon {
    position: absolute;
    top: 5px;
    right: 35px;
  }
  .delete-icon:hover {
    content: url("../../assets/images/delete-icon-hover.svg");
  }
}

.exist-size-chart-list-map {
  div:last-of-type{
    .size-chart-item-card {
      margin-bottom: 0;
    }
  }
}

.size-chart-external {
  .exist-size-chart-list {
    .size-charts-list-scroll {
      margin-right: 0;
      .exist-size-chart-list-map {
        .size-chart-item-card {
          height: 58px;
        }
      }
    }
  }
}
.dropdown-check-list {
  display: inline-block;
  margin-right: 5px;
}
.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 2px solid white;
  margin-bottom: 5px;
  background: white;
  width: 55px;
  text-align: center;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 5px solid white;
  border-top: none;
  background: white;
}
.dropdown-check-list ul.size-chart-search {
  padding: 2px;
  display: none;
  margin: 0;
  border: 5px solid white;
  border-top: none;
  background: white;
}

.dropdown-check-list ul.items li {
  //list-style: none;
  //height: 40px;
  //border-bottom: 1px solid #ddd;

  width: 100%;
  display: flex;
  //justify-content: space-between;
  border-bottom: 1px solid #ddd;
  align-items: center;
  height: 35.6px;
  margin-bottom: 7px;
  cursor: pointer;
  text-align: left;
}
.dropdown-check-list ul.size-chart-search li {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ddd;
  align-items: center;
  height: 35.6px;
  margin-bottom: 7px;
  cursor: pointer;
  text-align: left;
}
.dropdown-check-list.visible .items {
  display: block;
  width: 355px;
  width: 296px;
  height: 175px;
  padding: 18px 16.5px 20px;
  object-fit: contain;
  border-radius: 11px;
  background-color: #fff;
  top: 17%;
  //left: 0%;
}
.dropdown-check-list.visible .size-chart-search {
  display: block;
  width: 296px;
  padding: 18px 16.5px 20px;
  object-fit: contain;
  border-radius: 11px;
  background-color: #fff;
  top: 17%;
  //left: 0%;
}
.gender-list-man:hover {
  background-color: #f4f4f4;
}
.gender-list-woman:hover {
  background-color: #f4f4f4;
}
.gender-list-boys:hover {
  background-color: #f4f4f4;
}
.gender-list-girls:hover {
  background-color: #f4f4f4;
}
.gender-list-man {
  &.check {
    background-color: #f4f4f4;
  }
}
.gender-list-woman {
  &.check {
    background-color: #f4f4f4;
  }
}
.gender-list-boys {
  &.check {
    background-color: #f4f4f4;
  }
}
.gender-list-girls {
  &.check {
    background-color: #f4f4f4;
  }
}
.size-chart-item-card:hover {
  border: 2px solid #c6c6c6;
}

.size-chart-tip {
  // width: 303px;
  margin: 9px 0 14px 0;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: relative;
  padding-left: 15px;

  &:empty{
    display: none;
  }

  &__body {
    align-items: center;
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      color: #f8686e;
      width: 185px;
      display: inline-block;
      margin-left: 25px;
      margin-right: 25px;
    }
  }
  &__info {
    height: 30px;
    margin-top: 20px;
    align-self: flex-start;
    
  }
  &__close {
    right: 5px;
    top: 5px;
    height: 10px;
    cursor: pointer;
    position: absolute;
  }

  &__add-icon {
    height: 19px;
    position: relative;
    top: 5px;
  }

  &__arrow-container {
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
  }
}
