.mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    color: #201c51;

    &__icon {
        margin-bottom: 25px;
    }

    .image-container {
        background-color: #f1f0f0;
        padding: 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

    }

    &__title{
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 20px;
    }

    &__p {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
    }
}