.drop-down {
  height: 120px;
  // margin: 18px 23px 30px 221.5px;
  // padding: 33px 99px 40px 94px;
  object-fit: contain;
  border-radius: 7px;
  border: dashed 2px rgba(242, 97, 103, 0.5);
  background-color: rgba(242, 97, 103, 0.03);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .drop-down-content-wrapper {
    display: flex;
    justify-content: center;

    .remove-file-button {
      color: #f26167;
      cursor: pointer;
      font-weight: bold;
    }

    img {
      margin-right: 22px;
    }
    p {
      margin: 0;
      margin-bottom: 7px;
      margin-top: 7px;
      padding: 0;
      object-fit: contain;
      font-family: Roboto;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #b2b2b2;

      span {
        color: #f26167;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

}
