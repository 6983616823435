
.widget-preview-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        font-size: 15px;
        line-height: 1.87;
        color: #000;
        text-align: center;
        margin-bottom: 15px;
    }

    .widget-preview-outer-box {
        width: 340px;
        height: 240px;
        border-radius: 12px;
        background: #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .widget-preview-inner-box {
            border: 1.5px solid #d1d1d1;
            box-shadow: 10px 20px 40px #aaa7a7;
            display: flex;
            align-items: center;
            justify-content: center;

            .widget-preview-text {
                font-family: Roboto;
                font-size: 15px;
                margin-right: 10px;
                margin-left: 10px;
            }

            .widget-preview-logo {
                width: 20px;
                height: 20px;
                border: none;
                background-size: contain;
            }
        }
    }

    .widget-reset-to-default-button-section {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        cursor: pointer;

        .reset-to-default-button {
            font-family: Roboto;
            font-size: 14px;
            color: #898989;
            margin-left: 6px;
        }
    }
}