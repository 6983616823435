.size-range-box {
  width: 100%;
  border: 1px solid #c6c6c6;
  display: flex;

  .size-range-min {
  }

  .size-range-max {
    border-left: 1px solid #c6c6c6;
  }

  input::placeholder{
    text-align: center;
    font-size: 16px;
    color: #b2b2b2;
    text-align: center;
  }

  input:focus{
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  input{

    border: none;
    background: transparent;
    text-align: center;
    color: #211c51;
    font-size: 17px;
    width: 50%;
    background: transparent;
    border:none;
  }

  input[type="text"]:disabled {
    background: #ededed !important;
  }

}