.jeans-size-chart-creator-wrapper{
    width: 1040px;
  height: 612px;
  padding: 56px 98px;
  box-sizing: border-box;
  position: fixed;
  z-index: 500;
  top: 50%;
  left: 50%;
  margin-top: -311px;
  margin-left: -520px;
  background: #F7F7F7;

  .x-img{
    width: 25px;
    position: absolute;
    right: 98px;
    cursor: pointer;
    }

  .basic-size-table-header {
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    text-align: center;
    color: #211c51;
  }


}
.vl-width {
  border-left: 1px solid #c6c6c6;
  height: 40px;
  margin-top: 15px;
  display: none
  
}
.vl-width-length {
  border-left: 1px solid #c6c6c6;
  height: 40px;
  margin-top: 15px;
  display: none
  

}
.vl-length-matrix {
  border-left: 1px solid #c6c6c6;
  height: 40px;
  margin-top: 15px;
  display: block
}

.vl-matrix{
  border-left: 1px solid #c6c6c6;
  height: 40px;
  margin-top: 15px;
  display: block
}