.DialogActions{
    align-items: center;
}
.MuiDialog-paperWidthSm {
    align-items: center;
}
.MuiDialogTitle-root{
    padding: 0 !important;
}

.MuiDialogActions-root {
padding: 0!important;    
}
.MuiDialogContent-root{
    padding-top: 0;
}
.MuiDialogContent-root{

}

.alert-title-bar {
    // background-color: tomato;
    padding: 0;
    margin-bottom: 15px !important;
    font-size: 25px !important;
    
}
.alert-button {
    width: 160px !important;
    height: 49px !important;
    padding: 0 !important;
    margin: 0 !important;
    // margin: 15px;
    

}
.MuiTypography-h6{
    font-size: 25px !important;

}

.alert-buttons-wrapper {
    width: 351px;
    display: flex;
    justify-content: space-evenly !important;
}


.dialog {
    padding: 77px 0px!important;
}
.MuiDialog-paperWidthSm {
    padding: 40px !important;
    margin: 0 !important;
    max-width: 500px!important;
}

#myProgress {
    width: 100%;
    background-color: grey;
  }
  
  #myBar {
    width: 1%;
    height: 30px;
    background-color: green;
  }