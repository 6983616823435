.widget-body {
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 20px;

    &__hint {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 2;
        color: #848484;
    }
    .size {
        width: 100%;
        margin-top: 20px;
        border-bottom: 1px solid #f4f4f4;
        padding-bottom: 30px;

    }
    .radius {
        width: 100%;
        margin-top: 10px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 1.87;
        color: #000;

    }
}

.widget-body__header {
    margin-bottom: 25px;
}

.MuiSlider-markActive {
    background-color: #f8686e !important;
}