$mysize-pink: #f26167;
$mysize-blue: #211c51;

.registraion-container {
    margin: auto;
    display: flex;
    position: relative;
    gap: 80px;
    max-width: 1062px;
    min-width: 1062px;
    margin-block-end: 100px;

    .auth-modal {
        display: block;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba(247, 247, 247, 0.671);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left-side {
        display: flex;
        flex-direction: column;
        width: 500px;

        h3 {
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $mysize-blue;
        }
        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;

            label {
                position: absolute;
                top: 10px;
                left: 20px;
                transition: 0.2s;
                user-select: none;
                color: #848484;
            }

            input:focus ~ label,
            label.focused {
                top: 5px;
                font-size: 10px;
                transition: 0.2s;
            }

            input {
                height: 48px;
                margin-bottom: 15px;
                border-radius: 3px;
                background-color: #fff;
                border-width: 0;
                padding-left: 20px;
                width: 100%;
                box-sizing: border-box;
                line-height: 48px;
                padding-top: 10px;

                &:focus {
                    outline: none;
                }
            }

            .sign-in-measurement-unit {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                p {
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #848484;
                    margin-right: 10px;
                }
                .switcher-container {
                    display: flex;

                    div {
                        width: 77px;
                        height: 48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3px;
                        background-color: #fff;
                        margin-left: 7px;
                        cursor: pointer;
                        font-size: 19px;
                        font-weight: bold;

                        color: #aaa;
                    }

                    .active {
                        border: 1px solid $mysize-pink;
                        color: $mysize-pink;
                        box-sizing: border-box;
                    }
                }
            }

            .update-container {
                margin-top: 21px;
                input {
                    display: inline-block;
                    margin: 0;
                    height: 13px;
                    width: fit-content;
                }

                p {
                    display: inline-block;
                    margin: 0;
                    margin-left: 10px;
                    font-size: 16px;
                    color: #848484;
                }
            }
            .signup-button {
                width: 100%;
                height: 60px;
                background-color: $mysize-pink;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 19px;
                font-weight: bold;
                color: #fff;
                margin-top: 32px;
                cursor: pointer;
                border-radius: 3px;
            }

            .signin-inner-container {
                color: $mysize-blue;
                text-align: center;
                font-weight: 600;

                a {
                    color: $mysize-pink;
                    font-weight: bold;
                    cursor: pointer;
                }
            }

            .footer {
                margin: auto;
            }

            .input-error {
                border: 2px $mysize-pink solid;
                box-sizing: border-box;
            }

            .input-wrapper-auth {
                position: relative;
                width: 100% !important;

                .error-text {
                    position: absolute;
                    // top: 17px;
                    // top: calc(50% - 13px);
                    transform: translateY(-25%);
                    // right: 0;
                    color: $mysize-pink;
                    margin-right: 50px;
                    font-weight: bold;
                    user-select: none;
                    font-size: 12px;
                    top: 55px;
                    left: 20px;
                }

                .eye-icon {
                    position: absolute;
                    top: calc(50% - 13px);
                    right: 20px;
                    cursor: pointer;
                    &.open-eye-icon {
                        top: calc(50% - 12px);
                    }
                }
            }
        }
    }

    .right-side {
        img {
            width: 457px;
            height: 630px;
            object-fit: contain;
        }
    }
}

@media only screen and (max-width: 1085px) {
    .registraion-container {
        min-width: unset;
        justify-content: center;
        .right-side {
            display: none;
        }

        .update-container {
            display: flex;
            input {
                display: flex !important;
                justify-content: center;
                align-items: center;
                min-height: 50px;
            }
            p {
                display: flex !important;
                justify-content: center;
                align-items: center;
                min-height: 50px;
                max-width: 90%;
                padding: 0;
            }
        }
    }

    *::-webkit-scrollbar {
        width: 0;
    }
}

@media only screen and (max-width: 600px) {
    .registraion-container {
        padding: 0 5%;
        min-width: 270px;
        margin-block-end: 200px;
        .footer,
        .footer a {
            text-align: center;
            font-size: 12px !important;
        }
    }
}

// @media only screen and (max-width: 399px) {
//     .registraion-container {
//         .left-side {
//             width: 300px;
//         }
//     }
// }
