@import "../../assets/css/helpers.scss";

.account {
  width: 1039px;
  margin: auto;
  font-size: 16px;
  overflow-y: auto;
  height: calc((100vh - 60px) - 10vh);

  &::-webkit-scrollbar-track {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    border-left-color: #f7f7f7;
  }

  .plan-capacity-error {
    padding: 15px 25px;
    font-weight: 500;
  }
  .account-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    display: none;

    .account__title {
      font-size: 20px;
      font-weight: bold;
      color: #211c51;
    }

    .logout {
      font-size: 14px;
      line-height: 2;
      color: #f26167;
      cursor: pointer;
      // display: none;
      margin-right: 30px;
      padding: 2px 10px;
      // transition: all 0.2s linear;

      &:hover {
        border-radius: 50px;
        background-color: #feeaea;
        transition: all 0.2s linear;
      }
    }
  }
  .widget-accout {
    width: 1040px;
    height: 310px;
  }
  .iframe-wrapper {
    height: auto !important;
  }

  .billing {
    margin-top: 32px;
    .your-billing-header {
      display: flex;
      align-items: center;
      // width: 1150px;
      height: 52.7px;
      padding: 0 30px;
      // object-fit: contain;
      background-color: #efefef;
      // margin-top: 32px;
    }
  }

  .billing-history-header {
    display: flex;
    align-items: center;
    height: 52.7px;
    padding: 0 30px;
    background-color: #efefef;
    margin-top: 32px;
    .date {
      padding-left: 57px;
    }

    img {
      padding-right: 20px;
    }
  }
  .billing-history-section {
    .billing-payment-row {
      // width: 1150px;
      height: 52.7px;
      margin-bottom: 3px;
      padding: 0 318px 0 29px;
      -o-object-fit: contain;
      object-fit: contain;
      background-color: #ffffff;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      width: 400px;
      max-width: 400px;

      p {
        display: inline;
        // width: 300px;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .your-plan-section {
    height: 52.7px;
    padding-left: 25px;
    margin-left: 0;
    display: flex;
    align-items: center;
    // padding: 15.5 47px 16.2px 29px;
    object-fit: contain;
    background-color: #fbfbfb;
    // margin-top: 60px;
  }
  .plan-details-section {
    // height: 116px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    padding: 40px 39px 70px 29px;

    .switch-plan-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
      font-size: 12px;
    }

    a {
      text-decoration: none;
      color: #fff;
      border-radius: 20px;
      background-color: #f8686e;
      font-size: 16px;
      text-transform: uppercase !important;
      padding: 10px 10px;
      font-family: "Roboto", sans-serif;
    }
    .plan-name-section {
      // width: 150px;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      width: 200px;

      p {
        padding: 0;
        margin: 0;
      }
    }
    .current-plan-info-section {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      height: 59px;
      width: 100% !important;

      p {
        padding: 0;
        margin: 0;
      }

      .popup-window {
        width: 238px;
        height: 38px;
        position: absolute;
        top: -42px;
        left: 43px;
        padding: 1px 19px;
        object-fit: contain;
        border-radius: 10px 10px 10px 0;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          height: 18px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: left;
          color: #646464;
          display: inline;
        }
      }

      .reccomendation-count {
        display: flex;
        gap: 10px;
        .red-alert-img {
          cursor: pointer;
          z-index: 10;
          align-self: flex-start;
        }
      }
      .myProgress {
        margin-top: 5px;
        width: 100%;
        background-color: #e8e8e8;
        border-radius: 25px;

        #myBar-0 {
          width: 1%;
          height: 5px;
          background-color: #f7676d;
          border-radius: 25px;
        }
      }
    }
  }

  .account__uninstall-button {
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.external {
  width: 748px !important;
  min-width: 748px;
  max-width: 748px;

  .account-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .account__title {
      font-size: 20px;
      font-weight: bold;
      color: #211c51;
      margin-left: 25px;
    }

    .logout {
      font-size: 14px;
      line-height: 2;
      color: #f26167;
      cursor: pointer;
    }
  }
  .widget-accout {
    width: 1040px;
    height: 310px;
  }
  .iframe-wrapper {
    height: auto !important;
  }

  .billing {
    margin-top: 32px;
    .your-billing-header {
      display: flex;
      align-items: center;
      // width: 1150px;
      height: 52.7px;
      padding: 0 30px;
      // object-fit: contain;
      background-color: #efefef;
      // margin-top: 32px;
      .date {
        padding-left: 57px;
      }
    }
  }

  .billing-history-header {
    display: flex;
    align-items: center;
    height: 52.7px;
    padding: 0 30px;
    background-color: #efefef;
    margin-top: 32px;

    img {
      padding-right: 20px;
    }
  }
  .billing-history-section {
    .billing-payment-row {
      // width: 1150px;
      height: 52.7px;
      margin-bottom: 3px;
      padding: 0 318px 0 29px;
      -o-object-fit: contain;
      object-fit: contain;
      background-color: #ffffff;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      width: 400px;
      max-width: 400px;

      p {
        display: inline;
        // width: 300px;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .your-plan-section {
    height: 52.7px;
    margin-left: 0;
    display: flex;
    align-items: center;
    padding: 0 30px;
    background-color: #efefef;
    // margin-top: 60px;
  }
  .plan-details-section {
    // height: 116px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    padding: 30px 39px 70px 29px;

    .switch-plan-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      min-width: 140px;
      max-width: 140px;
      font-size: 12px;
      position: relative;
      left: 4px;
    }

    a {
      text-decoration: none;
      color: #fff;
      border-radius: 20px;
      background-color: #f8686e;
      font-size: 16px;
      text-transform: uppercase !important;
      padding: 10px 10px;
      font-family: "Roboto", sans-serif;
    }
    .plan-name-section {
      // width: 150px;
      display: flex;
      flex-direction: column;
      // margin-right: 40px;
      gap: 10px;

      width: 295px;
      max-width: 295px;
      word-wrap: break-word;

      p {
        padding: 0;
        margin: 0;
      }
    }
    .current-plan-info-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      justify-content: space-between;
      height: 59px;
      // width: 284px !important;
      width: 100% !important;
      // padding-left: 100px;

      p {
        padding: 0;
        margin: 0;
        &:last-of-type {
          line-height: 1.4;
        }
      }

      .popup-window {
        width: 238px;
        height: 38px;
        position: absolute;
        top: -52px;
        left: 80px;
        padding: 1px 19px;
        object-fit: contain;
        border-radius: 10px 10px 10px 0;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        // z-index: 16;

        p {
          height: 18px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: left;
          color: #646464;
          display: inline;
        }
      }

      .reccomendation-count {
        display: flex;
        gap: 10px;
        .red-alert-img {
          cursor: pointer;
          z-index: 15;
          align-self: flex-start;
        }
      }
      .myProgress {
        margin-top: 5px;
        width: 100%;
        background-color: #e8e8e8;
        border-radius: 25px;

        #myBar-0 {
          width: 1%;
          height: 5px;
          background-color: #f7676d;
          border-radius: 25px;
        }
      }
    }
  }
}

.plan-history {
  margin-top: 30px;
  .your-plan-history-header {
    background-color: #fbfbfb;
    padding-left: 29px;
    padding-right: 25px;
    font-size: 16px;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .plan-history-item {
    display: flex;
    justify-content: flex-start;
    // width: 1150px;
    height: 116px;
    padding: 0px 39px 0px 29px;
    object-fit: contain;
    background-color: #ffffff;
    margin-bottom: 3px;

    .plan-name {
      // width: 312px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #b4b4b4;
      margin-right: 40px;
      width: 295px;
      max-width: 295px;
      padding-top: 12px;
    }

    .plan-history-capacity-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding: 0 75px;
      width: 100% !important;

      .history-capacity {
        font-size: 20px !important;
        font-weight: bold !important;
        font-family: Roboto;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #b4b4b4;
        margin: 0;
      }

      .rec-reached {
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #b4b4b4;
        margin: 0;
      }
    }
  }
}

.your-billing-section {
  // // width: 1150px;
  // height: 80px;
  // margin: 0.3px 0 32px;
  // // padding: 30px 677px 29px 29px;
  // padding: 30px 29px 29px;
  // object-fit: contain;
  // background-color: #ffffff;
  // display: flex;
  // // justify-content: space-between;
  // justify-content: unset;
  // margin-bottom: 32px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  padding: 30px 39px 40px 29px;
  p {
    // opacity: 0.6;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #080808;

    &.bold {
      opacity: 1;
      font-weight: 700;
      margin-bottom: 0;
    }

    &:not(.bold) {
      margin-top: 0.2px;
    }
  }

  .billing-email {
    // min-width: 200px;
    // color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    width: 295px;
    max-width: 295px;
    word-wrap: break-word;
    margin-right: 40px;

    span {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: black;
    }

    p:last-of-type {
      max-width: 190px;
    }
  }

  .billing-card {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    position: relative;
    justify-content: space-between;
    height: 59px;
    width: 100% !important;

    .billing-edit-card-link {
      margin-inline-start: 10px;
      color: $mainClr;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    .billing-warning-message {
      display: block;
      color: $mainClr;
      font-size: 16px;
      width: max-content;
    }
  }
}

@media only screen and (max-width: 1019px) {
  *::-webkit-scrollbar {
    width: 20px !important;
  }
}
