.widget-language {
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 10px;



    &__label {
        margin-right: 10px;
        font-size: 15px;
        line-height: 1.87;
        color: #000;
    }

   
}
