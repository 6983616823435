.add-generc-chart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 363px;
    height: 78px;
    margin: 3px 14px 0 13px;
    padding: 0 21.2px 0 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ededed;
    background-color: #fff;
    margin: 0;
    padding: 0;
    margin-top: 4px;


    .left-wrapper {
        display: flex;
        align-items: center;


        .seperator {
            width: 1px;
            background-color: rgba(0, 0, 0, 0.123);
            height: 80%;
            margin-right: 10px;
            height: 50px;
        }

        .title {
            font-family: Roboto;
            font-size: 17px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #211c51;
        }
    }

    .right-wrapper {

        .add-button {
            padding: 6px 21px 6px 19px;
            background-color: #f8686e;
            font-family: Roboto;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            cursor: pointer;
            margin-right: 21px;
        }
    }
}