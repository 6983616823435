.tip{
    background-image: url('../../../assets//images/tip-box.svg');
    background-repeat: no-repeat;
    background-image: red;
    font-family: Roboto;
    height: 76px;
    width: 231px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    // text-align: left;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    text-align: center;

    
    .next-tip-wrapper {
        position: absolute;
        right: 10px;
        bottom: 20px;
        cursor: pointer;
        padding: 0;


        img {
            padding-left: 3px;
        }
    }

    .close-icon{
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        left: 10px;
        top: 8px;
    }
    div{
        padding: 0px 30px;
        padding-bottom:5px;
        text-align: left;
    }
}

.select_chart{
    top:40px;
    left: 35px;
}

.pair_chart{
    right: 80px;
    // top: 125px;
    top: 160px; 
    background-image: url('../../../assets/images/reversed-tip-box.svg') ;
    padding-top: 7px;

    img {
        margin-top: 5px;
    }

}

.preview_live {
    right: 88px;
    top: 130px;
    background-image: url('../../../assets/images/tip-box-arrow-top-left.svg');
    .close-icon{
        position: absolute;
        cursor: pointer;
        left: 10px;
        top: 16px;
    }
    div {
        padding-bottom: 0;
        padding-top: 5px;
    }
}

.add_chart {
    left: 20px;
}
.copy_chart {
    z-index: 0;
    // margin-top: -77px;

}
