.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(236, 236, 236);
  height: 100vh;
 

  .comp {
    background-color: red;
    height: 900px;
    width: 700px;
    margin-top: 20px;
    position: absolute;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); /* <-- this is the culprit */
  
}
.MuiDialog-paperWidthSm {
  padding: 15px;
}


.widget-settings {
  width: 1040px;
  // padding: auto;
 
}
// .widget-accout {
//   width: 1040px;
// }

// .iframe-wrapper{
//   height: 90vh;

// }