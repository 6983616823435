.exist-size-chart-list-map {
    // overflow-y: scroll;
    // height: calc(90vh - 113px);
}

.not-have-size-chart-p{
  object-fit: contain;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #919191;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 29px;
  // margin-right: 15px;
  margin: 0;
}

.size-charts-list-scroll{
  overflow-y: auto;
  // padding-right: 10px;
  // max-height: calc(90vh - 210px);;

  &::-webkit-scrollbar{
 
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: gray white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-left: 10px solid white;
}