.size-header-item {
  height: 60px;
  display: flex;
  display: inline-flex;
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #c6c6c6;
  justify-content: center;
  align-items: center;
  color: #1F1B45;
  font-family: Roboto;
  font-weight: bold;

  &-tool-tip {
    div {
      display: none;
      font-family: Roboto;
      justify-content: flex-start;
      align-items: center;
      position: fixed;
      width: 349px;
      height: 115px;
      object-fit: contain;
      border-radius: 5px 5px 5px 0;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.22);
      background-color: #ffffff;
      top: 50%;
      margin-left: 12px;
      left: 40%;
      padding-right: 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #1f1b45;
      z-index: 999999;
      img {
        width: 105px;
        height: 115px;
      }
    }
    &:hover div {
      display:flex;
    }
  }

  // .bra-input-name{
  // width: 57%;
  // border:none;
  // font-family: Roboto;
  // font-size: 18px;
  // font-weight: bold;
  // font-stretch: normal;
  // font-style: normal;
  // line-height: normal;
  // letter-spacing: normal;
  // text-align: center;
  // color: #1f1b45;
  // }

  // .bra-input-name:focus{
  //   outline: none;
  // }

  // .bra-input-name::placeholder{
  //   font-size: 18px;
  //   font-weight: bold;
  //   text-align: center;
  //   color: #b2b2b2;
  // }


  .check-box-input{
    background: inherit;
  }
}

