.unisex-size-charts-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-size: 15px;
    border-bottom: 1px #f4f4f4 solid;
    padding: 15px 0px;

    .Mui-checked.Mui-checked{
        color: #f1686e ;
    }
    .MuiButtonBase-root {
        min-width: 10px !important;
        color: #cbcbcb;
    }




    .unisex-size-charts-title {
        color: #000000;
        font-size: 15px; 
    }

    .unisex-size-charts-radio-buttons {
        margin-top: 10px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
    }
}


