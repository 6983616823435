.size-chart-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
    top: -40px;

  .buttons-wrapper {
    display: grid;
    grid-column: 3;
    grid-row: 3;
    grid-template-columns: 134px 134px 134px ;
    grid-gap: 12px;
  }



  .three-buttons-wrapper {
    display: grid;
    grid-column: 3;
    grid-template-columns: 134px 134px 134px;
    grid-gap: 22px;
  }



  .button {
    width: 134px;
    height: 51px;
    border: solid 1px #afafaf;
    background-color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: solid 1px #d8d8d8;
    &:hover
    {
      border: 2px solid #afafaf;
    }

    .category-icon {
        margin-right: 5px;
        
       
    }
    .active-icon path{
        fill: #f26167;
    }
    .unactive-icon path{
        fill: #9a9a9a;
    }
    

  }
  .active {
    border: solid 2px #f26167;
    color: #f26167;
    &:hover
    {
      border: solid 2px #f26167;
    }
  }
  .unactive{
    background-color: #ededed;
color: #9a9a9a;
  }
  h1 {
    height: 40px;
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
  }
  .next-button {
    width: 232px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f26167;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
    margin-top: 25px;
  }

  .advanced-sizeing-settings{
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
    color: #1f1b45;
    font-weight: 700;  
  
    &:hover {
      color: #f26167;
      transition: color 0.1s linear;
    }
  }
}
