.whats-new-wrapper{
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  // padding: 105px 10px 0px;
  object-fit: contain;
  position: fixed;
  z-index: 10000;
  .whats-new{
    width: 700px;
    height: 500px;
    margin: auto;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    border: solid 1px #707070;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__image {
      height: 450px;
      margin: auto;
      // padding-bottom: 20px;
    }
    &__title {
      position: absolute;
      font-size: 43px;
      font-weight: bold;
      line-height: 1.14;
      text-align: center;
      color: #211c51;
      width: 600px;
      top: 20px;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto;

    }

    .whats-new-start{
      height: 480px;
      margin: auto;
      margin-top: -20px;
      display: block;

    }
    .whats-new-step1{
      height: 480px;
      margin: auto;
      margin-top: -30px;
      display: none;
    }
    .whats-new-step2{
      height: 480px;
      margin: auto;
      margin-top: -30px;
      display: none;
    }
    .whats-new-step3{
      height: 480px;
      margin: auto;
      margin-top: -30px;
      display: none;
    }
    .whats-new-step4{
      height: 480px;
      margin: auto;
      margin-top: -30px;
      display: none;
    }
    .whats-new-step5{
      height: 480px;
      margin: auto;
      margin-top: -30px;
      display: none;
    }
    .whats-new-step6{
      height: 480px;
      margin: auto;
      margin-top: -30px;
      display: none;
    }
    .x-close-button{
      margin-left:650px;
      cursor: pointer;
      width: 15px;
      height: 15px;
    }
    .chart-btn-section{
      .next-button{
        cursor: pointer;
        border: none !important;
        width: 250px;
        height: 45px;
        padding: 10px 37px 11px;
        border-radius: 24px;
        background-color: #f8686e;
        object-fit: contain;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-top: -85px;
        position: absolute;
        margin-left: 225px;
      }
    }

  }

}