.bra-size-header-item {
    height: 60px;
    display: flex;
    display: inline-flex;
    height: 60px;
    box-sizing: border-box;
    border: 1px solid #c6c6c6;
    justify-content: center;
    align-items: center;
    color: #1F1B45;
    font-family: Roboto;
    font-weight: bold;
    flex-direction: column;
    position: relative;


    .bra-tooltip{
      width: 10px;
      height: 10px;
      position: absolute;
      right: 10px;


      .bra-tooltip-icon{
        display: inherit;
      // background-color: hotpink;
      z-index: 10000000;

      }
      .bra-tooltip-popup{
        // background-color: hotpink;
        z-index: 10000000;

        .tooltip-image{
          z-index: 10000000;
          display: inline;
        }
      }
    }


    img{
        display: none;
      }
  
  
    .bra-input-name{
    width: 59%;
    border:none;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f1b45;
    }
  
    .bra-input-name:focus{
      outline: none;
    }
  
    .bra-input-name::placeholder{
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #b2b2b2;
    }
  }

  .show-hover:hover{
    position: relative;
    border: solid 1px #f8686e;
  
    .close-img{
      position: absolute;
      display: block;
      top: 10px;
      left: 10px;
      cursor: pointer;
    }
  }