.add-generic-charts {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .add-charts-box {
    width: 439px;
    height: 90vh;
    border-radius: 8px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;

    .close-button {
      position: absolute;
      right: 20px;
      top: -10px;
      cursor: pointer;
    }

    .top-dashboard {
      margin-top: 25px;
      position: relative;

      .item {
        display: inline-block;
      }
      .back-arrow {
        margin-left: 40px;
        cursor: pointer;
        height: 19px;
        width: 11px;
      }

      .title {
        margin-left: 40px;
        font-family: Roboto;
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;

        color: #1f1b45;
      }

      .switcher {
        margin-left: 122px;
        width: 90px;
        height: 27px;
        display: flex;
        position: absolute;
        top: 17px;
        right: 0;

        .switcher-item {
          display: flex;
          border-radius: 4px;
          // border: solid 2px #c6c6c6;
          background-color: #ffffff;
          width: 30px;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-family: Roboto;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          cursor: pointer;
          color: #c6c6c6;
        }
        .active {
          border-color: #f8686e;
          color: #f8686e;
        }

        .cm-switcher {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          left: 1px;
          position: relative;
          border-right-color: #f8686e;
        }
        .in-switcher {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-left: none;
        }
      }
    }

    .list-wrapper {
      overflow-y: auto;
      height: calc(100% - 89px);

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        margin-right: 15px;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .gender-list {
        margin-bottom: 5px;

        .gender-title {
          width: 363px;
          height: 54px;
          cursor: pointer;

          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background-color: #211c51;
          margin-left: 41px;
          color: white;
          display: flex;
          align-items: center;

          justify-content: space-between;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0px;
          color: #ffffff;

          .title {
            margin-left: 15px;
          }

          .arrow {
            margin-right: 17px;
            cursor: pointer;
          }
        }
        .list {
          display: block;
          margin-top: 3px;
          list-style: none;
          .item {
            width: 363px;
            height: 55px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px #ededed;
            background-color: #ffffff;
            margin-bottom: 2px;
            list-style: none;
            display: flex;
            align-items: center;

            .category {
              width: 55px;
              height: 78px;
              display: inline-block;
              padding: 0 10px;
              // margin-left: 15px;
            }
            .category-title {
              display: inline-block;
              margin-left: 19px;
              font-family: Roboto;
              font-size: 17px;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #211c51;
              align-items: center;
            }

            .vl-container {
              display: inline-block;
              height: 100%;
            }
            .vl {
              border-left: 2px solid #dad8d8;
              height: 50px;
            }

            .edit-button {
              display: flex;

              margin-left: auto;
              margin-right: 15px;
              width: 68px;
              height: 33px;
              //background-color: #f8686e;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              cursor: pointer;
              color: #f8686e;
            }

            .green {
              background-color: #44c989;
              content: "Added";
            }

            .transparent-background {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  // .modal {
  //   z-index: -1;
  //   position: fixed;
  //   height: 100vh;
  //   width: 100vw;
  //   background-color: rgba(0, 0, 0, 0.4);
  //   top: 0;
  //   left: 0;
  // }
}
