
.button {
    text-transform: uppercase;
    width: 140px;
    padding: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
}

.main-page {
    height: 100%;
    padding: 50px 15% 0 20%;
    display: flex;
    flex-direction: column;

    &-upper {
        display: flex;
        justify-content: left;
        // width: calc(470px + 457px + 65px);
        // height: 480px;
        padding-top: 41px;
        margin-bottom: 40px;
    
        &-left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 15px;
    
            &-mysizeid-logo {
                width: 181px;
                height: 36.5px;
                object-fit: contain;
            }
    
            &-hi-there-title {
                font-family: Roboto;
                font-size: 95px;
                font-weight: 900;
                text-align: left;
                color: var(--dark-blue-grey);
                margin-top: 50px !important;
                line-height: 0.9;
            }

            // main-page-upper-left-add-payment-details-button
            &-add-payment-details-button {
                width: calc(100% - 20px);
                color: white;
                background-color: var(--rosy-pink);
                margin-top: 40px;
            }
    
            &-hi-there-subtitle,
            &-hi-there-thank-you-subtitle {
                white-space: pre-line;
                margin-top: 15px !important;
                font-family: Roboto;
                font-size: 29px;
                font-weight: 500;
                text-align: left;
                color: var(--dark-blue-grey);
                line-height: 1.3 !important;
            }
        }
    
        &-right {    
            &-woman-image {
                width: 410px;
                height: 480px;
                object-fit: contain;
                margin: 0 25px;
            }
        }
    }
}

@media only screen and (max-width: 1019px) {
    .main-page {
        // width: 100%;
        justify-content: center;
        padding: 50px 5% 0 15%;
        margin-bottom: 100px;
        
        &-upper-left {
            
            &-hi-there-title {
                margin-top: 50px;
            }

            // .button {
            //     padding: 10px;
            //     width: 130px;
            //     font-size: 12px;
            // }
        }
        &-upper-right {
            display: none;
        }
    }

    *::-webkit-scrollbar {
        width: 0;
    }
}

@media only screen and (max-width: 599px) {
    .main-page {
        padding: 0px 5% 0 10%;

        &-upper-left {
            width: 320px;
        }

        &-lower {
            margin-top: 60px;
        }
    }
}

@media only screen and (max-width: 399px) {
    .main-page {
        &-upper-left {
            width: 300px;
        }

        &-lower {
            margin-top: 60px;
        }
    }
}