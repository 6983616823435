
.product-list-header-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: white;
    height: 36px;
    align-items: center;
    margin-bottom: 5px;

    .control {
        font-family: arial;
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        padding-top: 3px;
        cursor: pointer;
        font-size: 16px;
    }
        .control input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    .control_indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 12px;
        width: 12px;
        background: #ffffff;
        border: 1px solid #c6c6c6;
        border-radius: 0px;
    }

    
    .control input:checked ~ .control_indicator {
        background: #f8686e;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        background: #e6647d;
    }
    .control input:disabled ~ .control_indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 4px;
        top: 1px;
        width: 3px;
        height: 6px;
        border: solid #ffffff;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }
    .control-checkbox .control_indicator::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 0rem;
        height: 0rem;
        margin-left: -2.992rem;
        margin-top: -3rem;
        background: #2aa1c0;
        border-radius: 0rem;
        opacity: 0;
        z-index: 99999;
    }
    @keyframes s-ripple {
        0% {
            transform: scale(0);
        }
        20% {
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(1);
        }
    }
    @keyframes s-ripple-dup {
       0% {
           transform: scale(0);
        }
       30% {
            transform: scale(1);
        }
        60% {
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(1);
        }
    }
    .control-checkbox input + .control_indicator::before {
        animation: s-ripple 250ms ease-out;
    }
    .control-checkbox input:checked + .control_indicator::before {
        animation-name: s-ripple-dup;
    }

    .product-list-header-left {
        display: flex;
        margin-left: 9px;
        align-items: center;

        .filter-img-section{
            width: 70.4px;
            height: 27px;
            background-image: url('../../assets/images/filter-not-clicked.svg');
            margin-left: 30px;
            cursor: pointer;
            position: relative;
        }

        .filter-img-section:hover{
            background-image: url('../../assets/images/filter-clicked.svg');
        }
        .pair-selected{
            object-fit: contain;
            font-family: Roboto;
            font-size: 14px;
            text-align: left;
            color: #f8686e;
            margin-left: 20px;
            cursor: pointer;
        }
    }

    .product-list-header-right {
        margin: 10px 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-right: 23px;
        color: #211c51;

    
        
        .expand-collapse {
            // height: 100%;
            display: flex;
            font-size: 14px;
            text-align: left;
            align-items: center;
            cursor: pointer;
       
    
    
    
        }
        .expand {

            &:hover {
                color: indianred;
            }
        }
        .collapse {
            &:hover {
                color: indianred;
            }            
        }

    }
}
.infinit-scroll-wrapper {
    overflow-y: auto;
    flex-grow: 1;
    // height: 400px;
    // background-color: #f8686e;
    height: calc(100% - 90px);
    // max-height: 100%;
}





.products-tip {
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    display: flex;
    align-items: center;
    margin: 10px 0;

    &__info{
        height: 30px;
        margin-left: 24px;

        
    }
    &__close{
        height: 10px;
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        
    }
    &__text{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        text-align: left;
        color: #f8686e;
        display: inline-block;
        margin-left: 20px;
    }

    &__down-arrow {
        margin-left: 35px;
    }
}
