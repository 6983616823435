.size-chart-added-successfully {
  position: fixed;
  width: 100%;
  bottom: -49px;
  left: 50%;
  height: 48px;
  transform: translateX(-50%);

  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  background-color: #4caf50;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;


  .circle-checked {
      margin-right: 10px;
  }
}
