.size-chart-gender {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
  }

  .buttons-wrapper {
    display: flex;
    width: 428px;
    // background-color: cornflowerblue;
    justify-content: space-between;

    .button {
      width: 134px;
      height: 51px;
      border: solid 1px #afafaf;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1f1b45;
      box-sizing: border-box;
      &:hover
      {
        border: 2px solid #afafaf;
      }
    }

    .active {
        border: solid 2px #f26167;
        color: #f26167;
        &:hover
        {
          border: solid 2px #f26167;
        }
       
    }
  }

  .next-button {
    width: 232px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f26167;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
    margin-top: 25px;
  }
}


.unable {
  background-color: rgb(173, 173, 173);
}
