.select-measurement-types {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -20px;


   h1{
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1f1b45;
  
   }

  .back-arrow {
    position: fixed;
    left: 96px;
    top: 60px;
    cursor: pointer;
  }
  .measurement-types-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    margin-top:14px;
    list-style: none;
    margin-bottom: 0;

    .measure-type {
      display: flex;
      width: 239.5px;
      flex-direction: column;
      align-items: center;
      margin-right: 0px;
      text-align: center;

      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1f1b45;
      }
      img {
        cursor: pointer;
        width: 170px;
        height: 170px;
      }
      .active {
        box-sizing: border-box;
        border: 2px solid #f26167;
      }
    }
    .measure-type:last-child {
      margin-right: 0;
    }
  }

  .next-button {
    width: 232px;
    height: 60px;
    background-color: #f26167;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    cursor: pointer;
  }
}
