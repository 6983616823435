.brand-identity-container {
  background-color: white;
  margin-bottom: 32px;

  .brand-identity-header {
    background-color: #efefef;
    padding-left: 25px;
    height: 52px;
    display: flex;
    align-items: center;
  }
  .brand-identity-body {
    padding: 30px 0;
    justify-content: space-between;
    display: flex;

    &__left-section {
      background-color: white;
      height: 116px;
      align-items: center;
      display: flex;
      margin-left: 30px;
    }
    .logo-preview {
      height: 100%;
      width: 100%;
      object-fit: contain;
      background-color: white;
    }
    &__info {
      margin-left: 30px;
      // width: 180px;
      max-width: 248px;
      position: relative;
    }
    &__brand-name {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.45;
      color: #000;
      display: flex;
      justify-content: flex-start;
    }
    &__edit-pen {
      cursor: pointer;
      margin-left: 5px;
      width: 10px;
      height: 31px;
    }
    &__brand-url {
      font-size: 16px;
      line-height: 1.25;
      text-align: left;
      color: #000;
      display: flex;
      justify-content: flex-start;
    }

    &__file {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
    &__brand-name-text {
      cursor: pointer;
      background-color: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      border: none;
      font-size: inherit;

      width: 100% !important;
    }
    &__website-text {
      cursor: pointer;
      background-color: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      border: none;
      font-size: inherit;

      width: 100% !important;
    }
    &__logo {
      width: 181px;
      height: 98px;
      background-color: #f7f7f7;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      margin-right: 20px;

      p {
        font-size: 13px;
        line-height: 1.54;
        text-align: left;
        color: #8b8b8b;
        padding: 0;
        margin: 0;
        margin-top: 5px;
      }
    }

    &__right-section {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    &__save-btn {
      width: 100px;
      height: 25px;
      border-radius: 100px;
      font-size: 13px;
      background-color: #aaa;
      display: flex;
      justify-content: center;
      align-items: center;
      // line-height: 1.56;
      color: #e0e0e0;
      // margin-right: 18px;
      padding: 5px 10px;
      cursor: not-allowed;
      
      &--active {
        cursor: pointer;
        background-color: #f8686e;
        color: white;
      }
    }

    &__retailer-token {
      color: #f26167;
      font-size: 13px;
      position: absolute;
      left: 0;
      bottom: -25px;
      width: max-content;
      display: flex;
      align-items: center;
      height: 25px;

      img {
        margin-left: 10px;
        cursor: pointer;
        height: 17px;
      }
      .check {
        height: 10px;
      }
    }
  }
}
