.select-sizing-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  h1 {
    height: 40px;
    font-family: Roboto;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f1b45;
  }

  .buttons-wrapper {
    display: flex;
    width: 482px;
    justify-content: space-between;

    .button {
      width: 238px;
      height: 51px;
      border: solid 1px #afafaf;
      background-color: #ffffff;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1f1b45;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      flex-direction: column;
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        // color: #211c51;
      }
    }
    
  
    .active {
        border: solid 2px #f26167;
        color: #f26167;
    }
    .unactive {
      background-color: rgb(209, 209, 209);
    }
  }

  .next-button {
    width: 232px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f26167;
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
    margin-top: 25px;
  }

}
