.standart-size-chart-creator-wrapper {


  .size-item {
    height: 50px;
    display: flex;
    box-sizing: border-box;
  
    img{
      display: none;
    }
  
    .enter-size {
      border: none;
      background: transparent;
      color: #1F1B45;
      font-family: Roboto;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      border: 1px solid #c6c6c6;
      width: 100%;
      box-sizing: border-box;
      border-left: none;
      padding: 1px 15px;
      // height: 50px;
    }
  
    .enter-size::placeholder {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #b2b2b2;
    }
  
    .enter-size:focus {
      outline: none;
    }
  }
  
  .empty-box {
    border: 1px solid #c6c6c6;
    height: 50px;
  }
  
  
  .size-item.header:hover{
    position: relative;
    border: solid 1px #f8686e;
  
    input {
      padding: 1px 14px;
    }

    img{
      position: absolute;
      display: block;
      top: 50%;
      left: 1.5px;
      transform: translateY(-50%) scale(0.7);
      cursor: pointer;
    }
  }
  
  
    .basic-size-table-header {
      font-family: Roboto;
      font-size: 31px;
      font-weight: bold;
      text-align: left;
      color: #211c51;
      margin-bottom: 10px;
      &-desc {
        margin-top: 0;
        object-fit: contain;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: #211c51;
      }
    }
  
  
    .size-chart-creator-table {
      width: 847px;
      height: auto;
      // border: 1px solid #c6c6c6;
      margin: 0 auto;
      background: white;
      position: relative;

      .add-col-size-icon{
        position: absolute;
        right: -60px;
        top: -6px;
        cursor: pointer;
        z-index: 5555;
      }
  
      .size-chart-creator-table-header {
        width: 847px;
        height: 61px;
        padding: 18px 29px;
        box-sizing: border-box;
        background-color: #211c51;
        color: white;
        align-items: center;
        display: flex;
  
  
        .size-chart-name {
          font-weight: bold;
          font-family: Roboto;
          padding-right: 20px;
        }
      }
  
  
      .standart-size-chart-creator-container {
        position: relative;
  
        .add-size-icon {
          position: absolute;
          bottom: -60px;
          left: -6px;
          cursor: pointer;
        }
  
        .sizes-container::-webkit-scrollbar {
          // width: 4px;
          width: unset;
          height: 4px;
          }

          .sizes-container::-webkit-scrollbar-thumb {
            background-color: #211c51;
            // outline: 5px solid white;
            }
        
        .sizes-container {
          display: grid;
          height: 212px;
          overflow: auto;

          
  
  
          .size-header-item {
            height: 60px;
            display: flex;
            display: inline-flex;
            height: 60px;
            box-sizing: border-box;
            border: 1px solid #c6c6c6;
            justify-content: center;
            align-items: center;
            color: #1F1B45;
            font-family: Roboto;
            font-weight: bold;
          }
        }
      }
    }
  }
