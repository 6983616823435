.placement-settings {
    background: white;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

   

    &-anchor {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 800px;

        &-input {
            width: 120px;
            margin-left: 20px;
            background: white;
            border-radius: 20px;
            border: 1px #e8e8e8 solid;
            padding: 7px 0px 7px 15px;
            font-size: 14px;
            outline-color: #f8686e;
            outline-width: 2px;
        }
    }

    &-type {
        // width: 300px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;


        &__title {
            margin-left: -260px;
        }
        &-selection-preview {
            // margin-left: 10px;
            margin-top: 10px;

            &-frame {
                width: 280px;
                display: flex;
                flex-direction: column;

                &-title {
                    width: 100%;
                    font-size: 14px;
                    text-align: center;
                    font-weight: bold;
                }
    
                &-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    &-image {
                        width: 100%;
                        padding: 20px 40px;
                        box-shadow: 0px 0px 20px #e8e8e8;
                    }
    
                    &-left-arrow {
                        margin-right: 30px;
                    }
    
                    &-right-arrow {
                        margin-left: 30px;
                    }

                    #placement-image-arrow {
                        margin-top: 130px;
                        height: 25px;
                        width: 25px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .placement-settings-save-button {
        width: 230px;
        margin-top: 20px;
        color: white;
        background: #f8686e;
        padding: 10px;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        height: 18px;
        max-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        &--disabled {
            background-color: #b7b7b7;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    .placement-settings-reset-to-default-button {
        display: flex;
        margin-top: 30px;
        cursor: pointer;

        .reset-to-default-button {
            font-family: Roboto;
            font-size: 14px;
            color: #898989;
            margin-left: 6px;
        }
    }

    .placement-settings-buttons-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}