.widget-settings {
  width: 1039px;
  margin: auto;

  .widget-settings {
    width: 1039px;
  }

  .iframe-wrapper {
    height: 90vh;
    margin-top: 21px;
  }

  .higher-position {
    margin-top: -15px;
  }

  .widget-settings-header {
    background: white;
    margin-top: 11px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &-open-user-guide {
      display: flex;
      position: absolute;
      right: 50px;
      top: 3px;

      &-text {
        font-size: 14px;
        font-weight: bold;
        margin-left: 10px;
      }
    }

    &__back-arrow {
      position: absolute;
      top: 50%;
      left: 57px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &__title {
      color: #211c51;
      font-weight: bold;
      text-align: center;
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.521);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__feedback {
    width: 341px;
    height: 209px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.21);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        height: 35px;
        width: 35px;
    }
    p {
        font-size: 16px;
        color: #1f1b45;
        font-weight: bold;
    }
    

  }
  

  &__wide-topbar {
    padding-top: 57px;
  }


  a:visited {
    color: black;
  }
}
