.product-filter-wrapper{
  width: 296px;
  min-height: 128px;
  z-index: 50001;
  padding: 18px 16.5px 20px;
  object-fit: contain;
  border-radius: 11px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: fixed;

  .title-list:hover{
    background-color: #f4f4f4;
}
    

  .title-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    align-items: center;
    height: 32.6px;
    // margin-bottom: 7px;
    padding: 5px;
    cursor: pointer;

    p{
        object-fit: contain;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
        margin: 0;
    }

    img{
        width: 7.5px;
        height: 12px;
        margin-right: 21px;
    }
  }

  .filter-option{

    .filter-select-list{
        height: 150px;
        overflow: scroll;

        .filter-item:hover{
            background-color: #f4f4f4;
        }

        .filter-item{
            display: flex;
             align-items: center;
             border-bottom: 1px solid #ddd;
             cursor: pointer;
             height: 30px;
            padding: 10px;

             .filter-check-box{
                 margin-right: 18.5px;
             }

             .control {
                font-family: arial;
                display: block;
                position: relative;
                padding-left: 30px;
                margin-bottom: 5px;
                padding-top: 1px;
                cursor: pointer;
                font-size: 16px;
            }
                .control input {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                }
            .control_indicator {
                position: absolute;
                top: 2px;
                left: 0;
                height: 12px;
                width: 12px;
                background: #ffffff;
                border: 1px solid #c6c6c6;
                border-radius: 0px;
            }
            
            
            .control input:checked ~ .control_indicator {
                background: #f8686e;
            }
            .control:hover input:not([disabled]):checked ~ .control_indicator,
            .control input:checked:focus ~ .control_indicator {
                background: #e6647d;
            }
            .control input:disabled ~ .control_indicator {
                background: #e6e6e6;
                opacity: 0.6;
                pointer-events: none;
            }
            .control_indicator:after {
                box-sizing: unset;
                content: '';
                position: absolute;
                display: none;
            }
            .control input:checked ~ .control_indicator:after {
                display: block;
            }
            .control-checkbox .control_indicator:after {
                left: 4px;
                top: 1px;
                width: 3px;
                height: 6px;
                border: solid #ffffff;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
            }
            .control-checkbox input:disabled ~ .control_indicator:after {
                border-color: #7b7b7b;
            }
            .control-checkbox .control_indicator::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 0rem;
                height: 0rem;
                margin-left: -2.992rem;
                margin-top: -3rem;
                background: #2aa1c0;
                border-radius: 0rem;
                opacity: 0;
                z-index: 99999;
            }
            @keyframes s-ripple {
                0% {
                    transform: scale(0);
                }
                20% {
                    transform: scale(1);
                }
                100% {
                    opacity: 0;
                    transform: scale(1);
                }
            }
            @keyframes s-ripple-dup {
               0% {
                   transform: scale(0);
                }
               30% {
                    transform: scale(1);
                }
                60% {
                    transform: scale(1);
                }
                100% {
                    opacity: 0;
                    transform: scale(1);
                }
            }
            .control-checkbox input + .control_indicator::before {
                animation: s-ripple 250ms ease-out;
            }
            .control-checkbox input:checked + .control_indicator::before {
                animation-name: s-ripple-dup;
            }

             p{
                font-size: 14px;
                color: #4a4a4a;
             }
        }


    }

    .save-filter{
        width: 330px;
        height: 44px;
        object-fit: contain;
        border-radius: 0px 0px 11px 11px;
        background-color: #f8686e;
        border: none;
        position: absolute;
        bottom: -22px;
        left: 0;
        cursor: pointer;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .filter-header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .and-or-section{
            display: flex;
            justify-content: space-between;
            width: 42px;


            p{
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #afafaf;
                cursor: pointer;
                margin: 0;
            }
        }

        img{
            cursor: pointer;
            margin-right: 10px;
        }
        
        .title-para{
        object-fit: contain;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #211c51;
        margin: 0;
        width: 150px;
        }


    }  


    .input-wrapper{
        position: relative;

    .filter-input{
        width: 262px;
        height: 37px;
        // margin: 16px 17px 38px;
        object-fit: contain;
        border-radius: 3px;
        border: solid 1px #afafaf;
        background-color: #fff;
        position: relative;
        padding-left: 30px;
    }
        .search-icon{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
            z-index: 500;
        }    
    }

  }
    
}
